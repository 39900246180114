import React from "react";

export default props => {
    const {theme, size, text, icon, dataToggle, dataTarget, disabled, onClick} = props;

    return (
        <button
            className={`btn btn-${theme ? theme : "primary"} ${size ? "btn-" + size : ""}`}
            data-toggle={dataToggle}
            data-target={dataTarget}
            disabled={disabled}
            onClick={onClick}>
            <span className="d-flex">
                <span className="material-icons">{icon}</span>
                &nbsp;
                <span>{text}</span>
            </span>
        </button>
    )
};