import React from "react";

function SinglePartner(props) {
    return (
        <div className="col-lg-8 col-md-10 mb-5 mt-5 card">
            <div className="row align-items-center">
                <div className="col-4">
                    <div className="p-md-5 border-right my-3 text-center py-4">
                        <img className="logo-img"
                             src={props.image}
                             alt={"Logo"}/>
                    </div>
                </div>
                <div className="col-8">
                    <div className="mr-5">
                        <h4 className="font-weight-bold">
                            {props.text}
                        </h4>
                        <a href={props.link} className="text-primary">
                            {props.linkText || props.link}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SinglePartner;