import React, {Component} from 'react';
import {getUserObject} from "../../../../helpers/login";
import {makeRequest} from "../../../../helpers/network_utils";
import {GET_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import SingleExam from "./single_exam";
import LoaderComponent from "../../../../components/LoaderComponent";

class StudentExamsMain extends Component {

    state = {
        loading: false,
        tests: []
    }

    componentDidMount() {
        this.getTakenExams();
    }

    getTakenExams = () => {
        this.setState({loading: true});
        const user = getUserObject();

        makeRequest(GET_REQUEST, endpoints.student_taken_tests(user.id), {}, response => {
            const tests = response.data.taken_tests;
            this.setState({
                tests: tests
            })
        }, () => {
            this.setState({error: true, loading: true});
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {tests, loading} = this.state;
        const {history} = this.props;
        return (
            <div className="row mx-auto justify-content-center">
                {
                    loading ? <LoaderComponent/> :
                        tests.length < 1 ?
                            <p className="font-weight-bold">
                                You haven't completed any exams yet.
                            </p> :
                            tests.map((exam) => <SingleExam key={exam?.id} exam={exam} history={history}/>)
                }
            </div>
        );
    }
}

export default StudentExamsMain;