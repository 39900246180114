import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST, PATCH_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import LoaderComponent from "../../../components/LoaderComponent";
import IconButton from "../../../components/IconButton";
import DateTimePicker from "react-datetime-picker";

class InstructorCourseEdit extends Component {
    state = {
        loading: false,
        errors: {},
        module: null,
        startTime: new Date(),
    }

    componentDidMount() {
        const {id} = this.props.match.params;

        makeRequest(
            GET_REQUEST,
            endpoints.courses + id,
            {},
            response => {
                const module = response.data;
                let startTime = new Date();
                if (module.start_time) startTime = new Date(module.start_time);
                this.setState({module, startTime});
            },
            error => {
                this.setState({error: true});
            },
        );

        setTimeout(() => {
            window.$("#moduleDescription").summernote({
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture', 'video']],
                    ['view', ['fullscreen', 'codeview', 'help']]
                ],
                height: 200,
                callbacks: {
                    onChange: description => {
                        this.setState({description});
                    }
                }
            });
        }, 1000);
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({loading: true, errors: {}});

        const {title, description, photo, startTime, zoom_link} = this.state;
        const {id} = this.props.match.params;
        const formData = new FormData();
        if (title) formData.append("title", title);
        if (description) formData.append("description", description);
        if (photo) formData.append("photo", photo);
        if (zoom_link) formData.append("zoom_link", zoom_link);
        if (startTime) formData.append("start_time", startTime.toISOString());

        makeRequest(
            PATCH_REQUEST,
            `${endpoints.courses}${id}/`,
            formData,
            response => {
                this.props.history.push(`/instructors/modules/${response.data.id}`);
            },
            error => {
                if (error.response) {
                    this.setState({errors: error.response.data})
                }
            },
            () => {
                this.setState({loading: false});
            }
        )
    }

    render() {
        const {loading, errors, module, startTime} = this.state;

        return (
            <div className="container-fluid">
                {module
                    ?
                    <div className="row py-4 mb-4">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <h2 className="mb-0">Update Module</h2>
                                <IconButton
                                    icon="cancel"
                                    text="Cancel"
                                    theme="outline-warning"
                                    onClick={() => this.props.history.goBack()}
                                    size="sm"/>
                            </div>
                            <hr/>
                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label>Module Title:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                defaultValue={module.title}
                                                onChange={event => this.setState({title: event.target.value})}
                                                required/>
                                            {errors.title && (
                                                <p className="mb-0 small text-danger">{errors.title[0]}</p>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label>Start Time:</label>
                                                <br/>
                                                <DateTimePicker
                                                    value={startTime}
                                                    onChange={date => this.setState({startTime: date})}
                                                    className="form-control"
                                                />
                                                {errors.start_time && (
                                                    <p className="mb-0 small text-danger">{errors.start_time[0]}</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Photo:</label>
                                                <input
                                                    onChange={event => this.setState({photo: event.target.files[0]})}
                                                    type="file"
                                                    className="form-control"/>
                                                {module.photo && (
                                                    <p className="small mb-0 text-muted">
                                                        Current: <a
                                                        href={module.photo}>{module.photo.slice(0, 30)}...</a>
                                                    </p>
                                                )}
                                                {errors.photo && (
                                                    <p className="mb-0 small text-danger">{errors.photo[0]}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Module Description:</label>
                                            <textarea id="moduleDescription" required
                                                      defaultValue={module.description}/>
                                            {errors.description && (
                                                <p className="mb-0 small text-danger">{errors.description[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>Zoom Link:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Zoom video call invitation link..."
                                                defaultValue={module.zoom_link}
                                                onChange={event => this.setState({zoom_link: event.target.value})}
                                            />
                                            {errors.zoom_link && (
                                                <p className="mb-0 small text-danger">{errors.zoom_link[0]}</p>
                                            )}
                                        </div>
                                        <div className="text-center">
                                            <button className="btn btn-primary" disabled={loading}>
                                                {loading ? "Loading..." : "Save Changes"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <LoaderComponent/>
                }
            </div>
        );
    }
}

export default InstructorCourseEdit;