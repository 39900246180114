import React, {Component} from 'react';
import Swal from "sweetalert2";
import {logout} from "../../helpers/login";
import {makeRequest} from "../../helpers/network_utils";
import endpoints from "../../routes/endpoints";
import {POST_REQUEST} from "../../values/globals";

class ResetPassword extends Component {

    state = {
        error: null,
        response: null,
        password: '',
        confirm_password: '',
        errors: {},
        isLoading: false
    };

    componentDidMount() {
        //Store the forgot password query parameters
        const {uid, token} = this.props.match.params;

        //If they don't exist, take the user back to the login page.
        if (!(uid && token)) {
            window.location.href = "/login";
        }
        this.setState({
            uid: uid,
            token: token
        })
    }

    submitForm = event => {
        event.preventDefault();
        const {errors} = this.state;
        errors.confirm_password = null;

        if (this.state.password !== this.state.confirm_password) {
            errors.new_password = ["Passwords don't match"];
            this.setState({
                errors: errors,
                isLoading: false
            })
            return;
        }

        this.setState({isLoading: true});

        const {uid, token} = this.state;

        makeRequest(POST_REQUEST, endpoints.reset_password_confirm, {
            new_password: this.state.confirm_password,
            uid: uid,
            token: token
        }, () => {
            Swal.fire(
                'Success!',
                'Your password was set successfully!',
                'success'
            ).then(() => logout());
            this.setState({
                response: null,
                error: null
            });

        }, (error) => {
            this.setState({
                errors: error.response.data,
                response: null,
                isLoading: false
            });
        })
    };

    render() {
        const {isLoading, errors} = this.state;
        return (
            <div style={{
                backgroundColor: "var(--secondary-color)"
            }}>
                <div className="row mx-auto justify-content-center p-lg-5 pt-5 pb-5">
                    <div className="col-lg-5">
                        <div className="bg-white rounded shadow">
                            <h3 className="pt-5 pb-4 pl-5 pr-5">
                                Reset Your Password
                            </h3>

                            <hr/>
                            <form onSubmit={this.submitForm} className="pl-5 pr-5 pb-5">
                                <div className="form-group">
                                    <input
                                        onChange={event => this.setState({password: event.target.value})}
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"/>
                                </div>
                                <div className="form-group">
                                    <input type="password"
                                           onChange={event => this.setState({confirm_password: event.target.value})}
                                           className="form-control"
                                           placeholder="Confirm Password"/>
                                    {
                                        errors.new_password && <p className="small text-danger"> {errors.new_password[0]}</p>
                                    }
                                </div>
                                <button disabled={isLoading} onClick={this.submitForm} style={{
                                    padding: "0.625rem 1.25rem"
                                }} className="button w-100 active">
                                    Reset Password
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ResetPassword;