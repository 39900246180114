import React, {Component} from 'react';
import {fetchInstructorCourses} from "../../../redux/instructors/actions";
import {connect} from "react-redux";
import placeHolder from "../../../assets/images/lcpb.png";
import {Link} from "react-router-dom";
import LoaderComponent from "../../../components/LoaderComponent";
import {getUserObject} from "../../../helpers/login";
import IconButton from "../../../components/IconButton";

const mapStateToProps = state => ({
    modules: state.instructorState.courses,
});

const mapDispatchToProps = dispatch => ({
    fetchCourses: query => dispatch(fetchInstructorCourses(query)),
});

class InstructorModuleList extends Component {
    componentDidMount() {
        const user = getUserObject();
        const query = `?instructor__account=${user.id}`;
        this.props.fetchCourses(query);
    }

    render() {
        const {modules} = this.props;

        return (
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>My Modules</h2>
                            <Link to={`/instructors/modules/add`}>
                                <IconButton
                                    icon="add"
                                    text="Add Module"
                                    size="sm"/>
                            </Link>
                        </div>
                        <hr/>
                    </div>
                </div>
                {modules
                    ?
                    <div className="row">
                        {modules.map(module => (
                            <div className="col-md-3 mb-4" key={module.id}>
                                <div className="card h-100">
                                    <img
                                        src={module.photo ? module.photo : placeHolder}
                                        alt="profile"
                                        className="card-img-top"/>
                                    <div className="card-body">
                                        <p className="small text-muted">{new Date(module.start_time).toLocaleString()}</p>
                                        <h6 className="text-capitalize">{module.title}</h6>
                                    </div>
                                    <div className="card-footer text-center">
                                        <Link to={`/instructors/modules/${module.id}`}
                                              className="btn btn-primary btn-sm">
                                            View Module
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {modules.length === 0 && (
                            <div className="col-12">
                                <p className="text-center">No modules added yet.</p>
                            </div>
                        )}
                    </div>
                    :
                    <LoaderComponent/>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstructorModuleList);