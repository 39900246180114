import React from "react";
import {useLocation, useHistory} from 'react-router';

function CustomNavLink(props) {

    const location = useLocation();
    const history = useHistory();
    const shouldBeActive = location.pathname.startsWith(props.to);

    return (
        <span data-toggle="collapse" data-target=".navbar-collapse.show" style={{fontWeight: "bold"}}
              onClick={() => {
                  history.push(props.to)
              }} className={`a-border ${shouldBeActive ? "a-border-active" : ""}`}>
            {props.children}
        </span>
    )
}

export default CustomNavLink;