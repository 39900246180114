import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import LoaderComponent from "../../../components/LoaderComponent";
import Topic from "../../students/group_discussions/chat/topic";

class ModuleDiscussions extends Component {
    state = {
        isLoading: false,
        groups: []
    }

    componentDidMount() {
        this.setState({isLoading: true})
        const id = this.props.match.params.id;
        makeRequest(
            GET_REQUEST,
            `${endpoints.group_discussions}`,
            {course: id},
                response => {
            this.setState({
                groups: response.data
            })
        }, null, () => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const {history, location} = this.props;

        const {groups, isLoading} = this.state;
        return (
            <div className="row mx-auto justify-content-center">
                <div className="col-lg-8 col-md-10 p-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className='font-weight-bold'>
                                    Discussions
                                </h5>
                                <button onClick={e => {
                                    e.preventDefault();
                                    history.goBack();
                                }} className="button active mb-3">
                                    Go Back
                                </button>
                            </div>
                            <hr className="m-0"/>
                            {isLoading ? <LoaderComponent/> :
                                groups.length > 0 ?
                                    groups.map(group => <Topic key={group.id} history={history} location={location}
                                                               topic={group}/>)
                                    :
                                    <p className="text-center p-3">
                                        You have not been added to any discussions with
                                        regard to this module.</p>

                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModuleDiscussions;