import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST, PATCH_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import LoaderComponent from "../../../components/LoaderComponent";
import IconButton from "../../../components/IconButton";
import {parseTime} from "../../../helpers/helper_functions";
import Swal from "sweetalert2";
import DateTimePicker from "react-datetime-picker";

class EditExams extends Component {
    state = {
        loading: false,
        errors: {},
        questions: [],
        duration: null,
        instructions: "",
        title: "",
        course: "",
        date: "",
        courses: []
    }

    componentDidMount() {
        const {id} = this.props.match.params;

        this.setState({loading: true});
        makeRequest(
            GET_REQUEST,
            `${endpoints.tests}${id}`,
            {},
            response => {
                const exam = response.data;
                this.setState({
                    duration: parseTime(exam.duration),
                    instructions: exam.instructions,
                    title: exam.name,
                    date: new Date(exam.starts_at)
                });
            },
            () => {
                this.setState({error: true});
            }, () => {
                this.setState({
                    loading: false
                })
            }
        );

        makeRequest(
            GET_REQUEST,
            `${endpoints.courses}`,
            {},
            response => {
                this.setState({
                    courses: response.data
                })
            },
        )
        this.initSummerNote();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.initSummerNote();
    }

    initSummerNote = () => {
        setTimeout(() => {
            window.$("#examInstructions").summernote({
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture', 'video']],
                    ['view', ['fullscreen', 'codeview', 'help']]
                ],
                height: 200,
                callbacks: {
                    onChange: instructions => {
                        this.setState({instructions});
                    }
                }
            });
        }, 500);
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({loading: true, errors: {}});

        let {title, instructions, duration, course, date} = this.state;
        duration.setSeconds(0);
        const {id} = this.props.match.params;
        const formData = new FormData();
        if (title) formData.append("name", title);
        if (instructions) formData.append("instructions", instructions);
        if (course) formData.append("course", course);
        if (duration) formData.append("duration", duration.toLocaleTimeString());
        if (date) formData.append("starts_at", date.toISOString());

        makeRequest(
            PATCH_REQUEST,
            `${endpoints.tests}${id}/`,
            formData,
            response => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Exam edited successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then(() => {
                    this.props.history.push(`/instructors/exams/${response.data.id}`);
                })
            },
            error => {
                if (error.response) {
                    this.setState({errors: error.response.data})
                }
                this.initSummerNote();
            },
        )
    }

    render() {
        let {loading, errors, duration, instructions, courses, date, title} = this.state;
        if (duration == null) duration = new Date();

        return (
            <div className="container-fluid">
                {loading
                    ? <LoaderComponent/>
                    :
                    <div className="row py-4 mb-4">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <h2 className="mb-0">Update Exam</h2>
                                <IconButton
                                    icon="cancel"
                                    text="Cancel"
                                    theme="outline-warning"
                                    onClick={() => this.props.history.goBack()}
                                    size="sm"/>
                            </div>
                            <hr/>
                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group col-lg-6 col-md-8">
                                            <label>Exam Title:</label>
                                            <input
                                                type="text"
                                                value={title}
                                                className="form-control"
                                                onChange={event => this.setState({title: event.target.value})}
                                                required/>
                                            {errors.name && (
                                                <p className="mb-0 small text-danger">{errors.name[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group col-lg-6 col-md-8">
                                            <label>Course</label>
                                            <select onChange={e => {
                                                this.setState({
                                                    course: e.target.value
                                                })
                                            }} className="form-control" style={{width: "100%"}}>
                                                <option>Select Course</option>
                                                {courses.map(course => <option
                                                    key={course.id} value={course.id}>{course.title}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-6 col-md-8">
                                            <label>Exam Duration:</label>
                                            <br/>
                                            <div className="row">
                                                <div className="col">
                                                    <p>Hours:</p>
                                                    <input className="form-control" type="number"
                                                           value={duration?.getHours() ?? ""}
                                                           onChange={event => {
                                                               if (duration == null) duration = new Date();
                                                               let value = event.target.value.length < 1 ? "0" : event.target.value;
                                                               duration.setHours(parseInt(value));
                                                               this.setState({duration: duration})
                                                           }}/>
                                                </div>
                                                <div className="col">
                                                    <p>Minutes:</p>
                                                    <input className="form-control"
                                                           value={duration?.getMinutes() ?? ""}
                                                           type="number" onChange={event => {
                                                        if (duration == null) duration = new Date();
                                                        let value = event.target.value.length < 1 ? "0" : event.target.value;
                                                        duration.setMinutes(parseInt(value));
                                                        this.setState({duration: duration})
                                                    }}/>
                                                </div>

                                            </div>
                                            {errors.duration && (
                                                <p className="mb-0 small text-danger">{errors.duration[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group col-lg-6 col-md-8">
                                            <label>Start Date and Time</label>
                                            <br/>
                                            <DateTimePicker value={date} onChange={(date) => {
                                                this.setState({
                                                    date: date
                                                })
                                            }}/>
                                            {errors.starts_at && (
                                                <p className="mb-0 small text-danger">{errors.starts_at[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>Exam Instructions:</label>
                                            <textarea id="examInstructions" required
                                                      defaultValue={instructions}/>
                                            {errors.instructions && (
                                                <p className="mb-0 small text-danger">{errors.instructions[0]}</p>
                                            )}
                                        </div>
                                        <div className="text-center">
                                            <button className="btn btn-primary" disabled={loading}>
                                                {loading ? "Loading..." : "Save Changes"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default EditExams;