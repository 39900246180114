import React, {Component} from 'react';
import {makeRequest} from "../../../../../helpers/network_utils";
import {DELETE_REQUEST, PATCH_REQUEST} from "../../../../../values/globals";
import endpoints from "../../../../../routes/endpoints";
import {asyncShowAlert, parseErrorResponse} from "../../../../../helpers/helper_functions";

class Choice extends Component {

    state = {
        loading: false,
        choice: this.props.choice,
        title: "",
        is_editing: false
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({loading: true});
        const {choice} = this.state;
        makeRequest(
            PATCH_REQUEST,
            `${endpoints.choices}${choice.id}/`,
            {
                is_correct: !choice.is_correct
            },
            response => {
                this.setState({
                    choice: response.data
                })
            },
            error => {
                asyncShowAlert('error', parseErrorResponse(error)).then();
            }, () => {
                this.setState({
                    loading: false
                })
            }
        )
    }

    handleEdit = () => {
        this.setState({loading: true});
        const {choice} = this.state;
        makeRequest(
            PATCH_REQUEST,
            `${endpoints.choices}${choice.id}/`,
            {title: this.state.title},
            response => {
                this.setState({
                    choice: response.data
                })
            },
            error => {
                asyncShowAlert('error', parseErrorResponse(error)).then();
            }, () => {
                this.setState({
                    loading: false,
                    is_editing: false
                });
            }
        )
    }

    handleDelete = () => {

        this.setState({loading: true});
        const {choice} = this.state;
        const {callback} = this.props;
        makeRequest(
            DELETE_REQUEST,
            `${endpoints.choices}${choice.id}/`,
            {},
            () => {
                if (callback) callback();
            },
            error => {
                asyncShowAlert('error', parseErrorResponse(error)).then();
            }, () => {
                this.setState({
                    loading: false
                })
            }
        )
    }


    render() {
        const {choice} = this.state;
        const {loading, is_editing} = this.state;
        let {title} = this.state;
        if (!title) title = choice.title;
        return (
            loading ?
                <div className="d-flex align-items-center justify-content-center rounded-pill border p-3 m-2">
                    <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                : <div className="d-flex align-items-center justify-content-between rounded-pill border p-3 m-2">
                    <span onClick={this.handleDelete} className="material-icons span-cs-button text-danger mr-3">
                        delete
                    </span>
                    {
                        is_editing ?
                            <div className="mb-3">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={title}
                                        className="form-control mb-3 mr-2"
                                        onChange={e => this.setState({title: e.target.value})}
                                        required/>
                                </div>
                                <div className="d-flex">
                                    <button className="btn btn-primary btn-sm mr-2 flex-grow-1"
                                            data-toggle="modal"
                                            onClick={this.handleEdit}>
                                        Save
                                    </button>
                                    <button className="btn btn-danger btn-sm flex-grow-1" data-toggle="modal"
                                            onClick={() => {
                                                this.setState({
                                                    is_editing: false
                                                })
                                            }}>
                                        Cancel
                                    </button>
                                </div>
                            </div> : <p className="m-0"> {choice.title}</p>
                    }
                    <div className="d-flex">
                        <span onClick={() => {
                            this.setState({
                                is_editing: true
                            })
                        }}
                              className={`material-icons mr-2 span-cs-button text-info`}>
                            edit
                        </span>

                        <span onClick={this.handleSubmit}
                              className={`material-icons span-cs-button ${choice.is_correct ? "text-success" : ""}`}>
                            check_circle
                        </span>
                    </div>

                </div>
        );
    }
}

export default Choice;