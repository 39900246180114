import React from "react";
import {Link} from "react-router-dom";
import IconButton from "../../components/IconButton";
import placeHolder from "../../assets/images/cool_background.png";

function SingleModuleMain(props) {
    const {module} = props;

    return (
        <div className="col-lg-4 col-md-6 mb-4">
            <div style={{overflow: "hidden"}} className="card h-100 mx-md-3">
                <img
                    style={{height: "30vh",objectFit: "cover"}}
                    src={module.photo ? module.photo : placeHolder}
                    alt="profile"
                    className="card-img-top"/>

                <div className="card-body text-left">
                    <p style={{fontSize: ".9rem"}}>{module.title}</p>
                </div>
                <div className="card-footer text-center bg-white">
                    <Link to={`/modules/${module.id}`}>
                        <IconButton theme="primary" text="View Module" size="sm"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SingleModuleMain;