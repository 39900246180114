import React from "react";

function NotFound() {
    return (
        <div className="p-5 text-center">
            <h1> 404 Not Found</h1>

            <h5 className="font-weight-light">
                The requested page could not be found
            </h5>
        </div>
    )
}

export default NotFound;