import React, {useEffect} from "react";
import {logout} from "../../helpers/login";

function Logout() {
    useEffect(() => {
        logout();
    })
    return <div/>
}

export default Logout;