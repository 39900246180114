import React, {Component} from 'react';
import IconButton from "../../../components/IconButton";
import {makeRequest} from "../../../helpers/network_utils";
import {PATCH_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import Swal from "sweetalert2";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";

class SubmitExam extends Component {

    state = {
        loading: false
    }


    handleSubmit = () => {
        const {exam} = this.props;
        makeRequest(
            PATCH_REQUEST,
            `${endpoints.tests}${exam?.id}/`,
            {is_submitted: !exam.is_submitted},
            response => {
                Swal.fire({
                    title: 'Success!',
                    text: `Exam ${!exam.is_submitted ? "Submitted" : "Un-Submitted"} successfully!`,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then(() => {
                    this.props.getExam();
                })
            },
            error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            },
        )
    }

    render() {
        const {exam} = this.props;
        return (
            <IconButton
                icon="save"
                text={`${exam?.is_submitted ? "Un-Submit" : "Submit"}`}
                theme="info"
                onClick={this.handleSubmit}
                size="sm"/>
        );
    }
}

export default SubmitExam;