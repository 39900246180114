import React, {Component} from 'react';
import {makeRequest} from "../../../../../helpers/network_utils";
import {DELETE_REQUEST} from "../../../../../values/globals";
import endpoints from "../../../../../routes/endpoints";
import {asyncShowAlert} from "../../../../../helpers/helper_functions";
import IconButton from "../../../../../components/IconButton";

class InstructorSubChapterDelete extends Component {
    state = {
        loading: false,
    }

    handleDelete = () => {
        this.setState({loading: true});

        const {id, chapterId, subChapterId} = this.props.match.params;

        makeRequest(
            DELETE_REQUEST,
            `${endpoints.sub_chapters}${subChapterId}/`,
            {},
            _ => {
                window.$("#subChapterDeleteModal").modal("hide");
                asyncShowAlert("success", "Chapter deleted successfully.")
                    .finally(() => {
                        this.props.history.push(`/instructors/modules/${id}/chapters/${chapterId}`);
                    });
            },
            error => {
                this.setState({loading: false});
                if (error.response) {
                    asyncShowAlert("error", "Could not delete chapter.").finally();
                }
            },
        )
    }

    render() {
        const {loading} = this.state;
        const {chapter} = this.props;

        return (
            <div>
                <div className="modal fade" id="subChapterDeleteModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="d-flex justify-content-center">
                                    <h4 className="mb-0">Delete Sub Chapter</h4>
                                </div>
                            </div>
                            <div className="text-center modal-body">
                                    <div>
                                        <p className="mb-0">
                                            Chapter: {chapter.title}
                                        </p>
                                        <p>Proceed to delete this chapter?</p>
                                        <IconButton
                                            theme="danger"
                                            icon="delete_forever"
                                            text={loading ? "Loading..." : "Delete Chapter"}
                                            onClick={this.handleDelete}
                                            disabled={loading}/>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstructorSubChapterDelete;