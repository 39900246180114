import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {DELETE_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {asyncShowAlert} from "../../../helpers/helper_functions";

class InstructorModuleDelete extends Component {
    state = {
        deleting: false,
    }

    deleteModule = () => {
        const {module} = this.props;

        this.setState({deleting: true});

        makeRequest(
            DELETE_REQUEST,
            `${endpoints.courses}${module.id}/`,
            {},
            response => {
                asyncShowAlert("success", "Module deleted successfully").finally();
                window.$("#moduleDeleteModal").modal("hide");
                this.props.history.push("/instructors/modules");
            },
            error => {
                if (error.response) {
                    asyncShowAlert("error", error.response.statusText).finally();
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
                this.setState({deleting: false});
            }
        )
    }

    render() {
        const {module} = this.props;
        const {deleting} = this.state;

        return (
            <div className="modal fade" id="moduleDeleteModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="mb-0">Confirm Delete</h5>
                        </div>
                        <div className="modal-body">
                            <h5 className="text-center">
                                Delete module: {module?.title}?
                            </h5>
                            <div className="text-center">
                                <button
                                    data-dismiss="modal"
                                    type="button"
                                    className="btn btn-outline-primary mx-1">
                                    Cancel
                                </button>
                                <button
                                    disabled={deleting}
                                    onClick={this.deleteModule}
                                    className="btn btn-danger mx-1">
                                    {deleting ? "Loading..." : "Delete"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstructorModuleDelete;