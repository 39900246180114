import React, {Component} from 'react';
import Choice from "./choices/choice";

class ChoicesModal extends Component {
    render() {
        const {choices, id, currentIndex} = this.props;
        return (
            <div className="modal fade" id={`choicesModal${id}`} tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Viewing Answer</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                choices.map((choice) =>
                                    <Choice key={choice.id} choice={choice} answer={choices[currentIndex]}/>
                                )
                            }
                            <hr className="my-3"/>
                            <h6 className="font-weight-bold text-center"> Your
                                Answer: {currentIndex != null ? choices[currentIndex].title : "Not Attempted"}</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChoicesModal;