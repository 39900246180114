import React, {Component} from 'react';
import Loader from "react-loader-spinner";

class LoaderComponent extends Component {
    render() {
        return (
            <div className="text-center">
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                />
            </div>
        );
    }
}

export default LoaderComponent;
