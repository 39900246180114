import React, {Component} from 'react';

class SingleExam extends Component {
    render() {
        const {exam, history} = this.props
        return (
            <div className="col-sm-10 p-2">
                <div className="card py-3 px-5">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <h6>
                                    {exam?.test?.name}
                                </h6>
                                <h3 className="font-weight-bold">Score: {exam?.test_score}%</h3>
                                <p>
                                    {exam?.test?.course}
                                </p>
                            </div>

                        </div>
                        <button onClick={() => {
                            history.push(`/students/exams/${exam?.test?.id}/done`);
                        }} className="btn btn-info">
                            View
                        </button>
                    </div>
                </div>

            </div>
        );
    }
}

export default SingleExam;