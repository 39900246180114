import React, {Component} from 'react';
import {connect} from "react-redux";
import {getStudentCourses} from "../../../../redux/student_courses/actions";
import LoaderComponent from "../../../../components/LoaderComponent";
import {getUserObject} from "../../../../helpers/login";
import NavButton from "../../../../components/nav_button";
import SingleModuleMain from "../../../modules/single_module_main";

const mapStateToProps = state => ({
    modules: state.studentCoursesState.enrolledCourses,
    isLoading: state.studentCoursesState.isLoading
});

const mapDispatchToProps = dispatch => ({
    fetchCourses: (id) => dispatch(getStudentCourses(id)),
});

class StudentModules extends Component {
    componentDidMount() {
        const user = getUserObject();
        this.props.fetchCourses(user.id);
    }

    getCourses = () => {
        let children = [];
        let {modules} = this.props;
        if (modules == null) {
            modules = [];
        }
        for (let i = 0; i < modules.length; i++) {
            children.push(<SingleModuleMain key={i} module={modules[i]}/>)
        }
        if (children.length < 1) {
            return <div className="p-lg-3">
                <p>You haven't enrolled in any courses yet</p>
                <NavButton to={"/modules"} children={"Enroll"}/>
            </div>
        }
        return children;
    }

    render() {
        const {isLoading} = this.props;
        return (
            <div>
                <div className="d-flex justify-content-between mb-4">
                    <h4>Enrolled Modules</h4>
                </div>
                <div className="row justify-content-center">
                    {
                        isLoading ? <LoaderComponent/> : this.getCourses()
                    }
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentModules);