import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {PATCH_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {asyncShowAlert} from "../../../helpers/helper_functions";
import {getUserObject, setUserObject} from "../../../helpers/login";

class InstructorProfileEdit extends Component {
    state = {
        errors: {},
        loading: false,
    }
    handleSubmit = event => {
        event.preventDefault();

        this.setState({loading: true});
        const {first_name, last_name, email, phone_number, photo} = this.state;

        const formData = new FormData();
        if (first_name) formData.append("first_name", first_name);
        if (last_name) formData.append("last_name", last_name);
        if (email) formData.append("email", email);
        if (phone_number) formData.append("phone_number", phone_number);
        if (photo) formData.append("photo", photo);

        makeRequest(
            PATCH_REQUEST,
            endpoints.current_user,
            formData,
            _ => {
                setUserObject();
                asyncShowAlert("success", "Profile updated successfully.")
                    .then(() => {
                        this.props.history.push("/instructors/account");
                    });
            },
            error => {
                if (error.response) {
                    this.setState({errors: error.response.data});
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
                this.setState({loading: false});
            },
        )
    }

    render() {
        const {errors, loading} = this.state;
        const user = getUserObject();

        return (
            <div className="container-fluid">
                <div className="row py-4">
                    <div className="col-12">
                        <h2>Update Profile</h2>
                        <hr/>
                    </div>
                    <div className="col-12 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label>First Name:</label>
                                            <input
                                                type="text"
                                                defaultValue={user.first_name}
                                                onChange={event => this.setState({first_name: event.target.value})}
                                                className="form-control"
                                                required
                                            />
                                            {errors.first_name && (
                                                <p className="small text-danger">{errors.first_name[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Last Name:</label>
                                            <input
                                                type="text"
                                                defaultValue={user.last_name}
                                                onChange={event => this.setState({last_name: event.target.value})}
                                                className="form-control"
                                                required
                                            />
                                            {errors.last_name && (
                                                <p className="small text-danger">{errors.last_name[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Email Address:</label>
                                            <input
                                                type="text"
                                                defaultValue={user.email}
                                                onChange={event => this.setState({email: event.target.value})}
                                                className="form-control"
                                                required
                                            />
                                            {errors.email && (
                                                <p className="small text-danger">{errors.email[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Phone Number:</label>
                                            <input
                                                type="text"
                                                defaultValue={user.phone_number}
                                                onChange={event => this.setState({phone_number: event.target.value})}
                                                className="form-control"
                                                required
                                            />
                                            {errors.phone_number && (
                                                <p className="small text-danger">{errors.phone_number[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Gender:</label>
                                            <select
                                                defaultValue={user.gender}
                                                onChange={event => this.setState({gender: event.target.value})}
                                                className="form-control"
                                                required
                                            >
                                                <option value="">Select gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                            {errors.gender && (
                                                <p className="small text-danger">{errors.gender[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Photo:</label>
                                            <input
                                                type="file"
                                                onChange={event => this.setState({photo: event.target.files[0]})}
                                                className="form-control form-control-file"
                                            />
                                            {user.photo && (
                                                <p className="small">
                                                    Current: <a href={user.photo}>{user.photo}</a>
                                                </p>
                                            )}
                                            {errors.photo && (
                                                <p className="small text-danger">{errors.photo[0]}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            disabled={loading}
                                            className="btn btn-primary">
                                            {loading ? "Loading..." : "Save Changes"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstructorProfileEdit;