import React, {Component} from 'react';
import {scheduleTokenRefreshes} from "../../helpers/network_utils";
import {getUserObject, saveRefreshToken, saveToken, setUserObject} from "../../helpers/login";
import {DOMAIN} from "../../values/globals";
import {Link} from "react-router-dom";
import {asyncShowAlert} from "../../helpers/helper_functions";
import CustomInput from "../../components/custom_input";

export class LoginForm extends Component {
    state = {
        errors: {},
        response: null,
        isLoading: false
    };

    handleSubmit = event => {
        event.preventDefault();
        const {redirect, reload} = this.props;
        const {email, password} = this.state;

        this.setState({isLoading: true, errors: {}});
        const body = JSON.stringify({email, password});
        const headers = {
            "content-type": "application/json",
        };

        fetch(`${DOMAIN}auth/jwt/create/`, {headers, body, method: "POST"})
            .then(response => response.json())
            .then(json => {
                const {access, refresh, detail} = json;

                if (detail) {
                    this.setState({
                        errors: json,
                        isLoading: false,
                    });
                } else {
                    saveToken(access);
                    saveRefreshToken(refresh);

                    setUserObject(
                        () => {
                        },
                        () => {
                            scheduleTokenRefreshes();

                            this.setState({
                                isLoading: false
                            });
                            const user = getUserObject();

                            //If user is logging in for the first time, take them to register.
                            if (user.is_first_time_user) {
                                this.props.history.push("/register")
                                return;
                            }

                            if (redirect) {
                                if (user.role === "instructor") {
                                    window.location.href = "/instructors/account/";
                                } else if (user.role === "student") {
                                    window.location.href = "/students/account/modules";
                                } else if (user.role === "admin") {
                                    window.location.href = "/modules";
                                }
                            } else if (reload) {
                                window.location.reload();
                            }
                        });
                }
            })
            .catch(error => {
                this.setState({isLoading: false});
                console.log(error);
                asyncShowAlert("error", error.message).finally();
            })
    }

    render() {
        const {isLoading, errors} = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                {errors.detail && (
                    <div className="alert alert-danger">
                        {errors.detail}
                    </div>
                )}
                <div className="form-group">
                    <label>Email:</label>
                    <CustomInput
                        type="email"
                        onChange={event => this.setState({email: event.target.value})}
                        placeholder="Email"
                        icon="mail_outline"
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Password:</label>
                    <CustomInput
                        type="password"
                        placeholder="Password"
                        icon="lock_open"
                        onChange={event => this.setState({password: event.target.value})}
                        className="form-control"
                        required
                    />
                </div>

                <div className="d-flex justify-content-end my-2">
                    <Link to={"/reset"}>Forgot password?</Link>
                </div>

                <button
                    style={{
                        padding: "0.625rem 1.25rem"
                    }}
                    disabled={isLoading}
                    className="button active w-100">
                    {isLoading ? "Loading..." : "Log In"}
                </button>

            </form>
        )
    }
}

class Login extends Component {
    render() {
        return (
            <div style={{
                backgroundColor: "var(--secondary-color)"
            }}>
                <div className="container">
                    <div className="col-lg-6 mx-auto py-5">
                        <div className="mx-auto">
                            <div className="card px-4">
                                <div className="card-header bg-white">
                                    <h4 className="font-weight-bold mt-4 mb-3">
                                        Login to your LCBP Account
                                    </h4>
                                </div>
                                <div className="card-body my-1">
                                    <LoginForm redirect history={this.props.history}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;