import React, {Component} from 'react';
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST, POST_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {asyncShowAlert} from "../../helpers/helper_functions";
import LoaderComponent from "../../components/LoaderComponent";
import {getUserObject} from "../../helpers/login";

const parse = require('html-react-parser');

class ModuleDetails extends Component {
    state = {
        module: null,
        is_enrolled: false,
        isLoading: false
    }

    componentDidMount() {
        const {id} = this.props.match.params;
        const user = getUserObject();
        if (user != null && Object.values(user).length > 0)
            makeRequest(
                POST_REQUEST,
                `${endpoints.check_enrollment}`,
                {
                    student: user.id,
                    course: id
                },
                response => {
                    this.setState({is_enrolled: response.data.enrolled});
                },
                () => {

                }, () => {
                    this.getModuleDetails(id);
                }
            )
        else
            this.getModuleDetails(id);
    }

    getModuleDetails = (id) => {
        makeRequest(
            GET_REQUEST,
            `${endpoints.courses}${id}/details`,
            {},
            response => {
                this.setState({module: response.data});
            },
            error => {
                if (error.response) {
                    asyncShowAlert("error", error.response.statusText).finally();
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
            }
        )
    }

    handleModuleOpen = () => {
        const {id} = this.props.match.params;
        const user = getUserObject();
        makeRequest(
            POST_REQUEST,
            `${endpoints.course_clicks}`,
            {course: id, student: user.id},
        )
    }

    handleEnroll = e => {
        e.preventDefault();
        const {id} = this.props.match.params;
        this.setState({
            isLoading: true
        })
        makeRequest(
            POST_REQUEST,
            `${endpoints.enroll_student}`,
            {course: id},
            () => {
                this.props.history.push(
                    `/learn/modules/${id}`
                )
            },
            error => {
                if (error.response) {
                    asyncShowAlert("error", error.response.statusText).finally();
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
            }, () => {
                this.setState({
                    isLoading: false
                })
            }
        )
    }

    render() {
        const {module, is_enrolled, isLoading} = this.state;
        const user = getUserObject();

        return (
            <div className="container-fluid">
                {module
                    ?
                    <div className="w-100">
                        <div className="py-4">
                            <div className="col-12">
                                <div className="jumbotron">
                                    <h3 className="font-weight-bold text-capitalize">{module.title}</h3>
                                    <hr className="my-4"/>
                                    {parse(module.description || "")}
                                    <p className="text-muted">Starts: {new Date(module.start_time).toLocaleString()}</p>
                                    {
                                        user.role === "student" ?
                                            is_enrolled ?
                                                <button
                                                    onClick={() => {
                                                        setTimeout(this.handleModuleOpen, 20000);
                                                        this.props.history.push(
                                                            `/learn/modules/${module.id}`
                                                        )
                                                    }}
                                                    className="btn btn-primary mr-3">
                                                    Continue Learning
                                                </button> :
                                                <button
                                                    onClick={this.handleEnroll}
                                                    disabled={isLoading}
                                                    className="btn btn-primary">
                                                    {isLoading ? "Loading" : "Enroll"}
                                                </button> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="mb-0">Module Chapters</h4>
                                    </div>
                                    <div className="card-body py-0">
                                        <ul className="list-group list-group-flush">
                                            {module?.course_chapters?.map(chapter => (
                                                <li className="list-group-item clickable" key={chapter.id}>
                                                    {chapter.title}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="p-5">
                        <LoaderComponent/>
                    </div>

                }
            </div>
        );
    }
}

export default ModuleDetails;