import React, {Component} from 'react';
import SingleModuleMain from "./single_module_main";
import {getCourses} from "../../redux/courses/actions";
import {connect} from "react-redux";
import LoaderComponent from "../../components/LoaderComponent";

const mapStateToProps = state => ({
    courses: state.coursesState.courses,
    isLoading: state.coursesState.isLoading
});

const mapDispatchToProps = dispatch => ({
    fetchCourses: () => dispatch(getCourses()),
});


class Module extends Component {
    componentDidMount() {
        this.props.fetchCourses();
    }

    render() {
        const {courses} = this.props;

        return (
            <div style={{backgroundColor: "var(--secondary-color)",minHeight: "50vh"}} className="w-100">
                <div className="container">
                    {/*<form>
                        <div className="row mx-auto justify-content-center">
                            <div className="col-lg-6 col-md-8 col-12">
                                <div
                                    className="d-flex flex-lg-row flex-column pt-5 p-md-5 align-items-center justify-content-center">
                                    <div style={{width: "100%"}} className="flex-fill mr-lg-4 mb-2">
                                        <div className="input-group">
                                            <input type="text"
                                                   className="form-control border-0 text-lg-left text-center"
                                                   placeholder="Search Course"
                                                   aria-describedby="emailHelp"/>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary mb-2">
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>*/}
                    {courses
                        ?
                        <div className="row mx-auto pt-5">
                            <div className="col-12">
                                <div className="row">
                                    {courses?.map(course => (
                                        <SingleModuleMain key={course.id} module={course}/>
                                    ))}
                                    {courses?.length === 0 && (
                                        <div className="text-center">
                                            <h3>
                                                Courses coming your way soon!
                                            </h3>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        :
                        <LoaderComponent/>
                    }
                </div>
            </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Module);