import InstructorProfile from "../pages/instructors/profile";
import InstructorCourseDetails from "../pages/instructors/modules/Details";
import InstructorChapterDetails from "../pages/instructors/modules/chapters/Details";
import InstructorChapterEdit from "../pages/instructors/modules/chapters/Edit";
import InstructorChapterDelete from "../pages/instructors/modules/chapters/Delete";
import InstructorSubChapterEdit from "../pages/instructors/modules/chapters/SubChapters/Edit";
import InstructorDiscussionDetails from "../pages/instructors/discussions/Details";
import InstructorModuleList from "../pages/instructors/modules/List";
import InstructorDiscussionsList from "../pages/instructors/discussions/List";
import InstructorProfileEdit from "../pages/instructors/profile/Edit";
import ExamsList from "../pages/instructors/exams/List";
import InstructorCourseAdd from "../pages/instructors/modules/Add";
import InstructorCourseEdit from "../pages/instructors/modules/Edit";
import ExamDetails from "../pages/instructors/exams/Details";
import EditExams from "../pages/instructors/exams/Edit";
import AddExam from "../pages/instructors/exams/Add";

const instructor_urls = {
    profile: {
        path: '',
        exact: true,
        component: InstructorProfile,
    },
    course_add: {
        path: 'modules/add',
        exact: true,
        component: InstructorCourseAdd,
    },
    chapter_details: {
        path: 'modules/chapters/:id',
        exact: true,
        component: InstructorChapterDetails,
    },
    chapter_edit: {
        path: 'modules/chapters/:id/edit',
        exact: true,
        component: InstructorChapterEdit,
    },
    chapter_delete: {
        path: 'modules/chapters/:id/delete',
        exact: true,
        component: InstructorChapterDelete,
    },
    course_details: {
        path: 'modules/:id',
        exact: true,
        component: InstructorCourseDetails,
    },
    course_edit: {
        path: 'modules/:id/edit',
        exact: true,
        component: InstructorCourseEdit,
    },
    course_chapter_details: {
        path: 'modules/:id/chapters/:chapterId',
        exact: true,
        component: InstructorCourseDetails,
    },
    course_sub_chapter_details: {
        path: 'modules/:id/chapters/:chapterId/sub/:subChapterId',
        exact: true,
        component: InstructorCourseDetails,
    },
    course_sub_chapter_edit: {
        path: 'modules/:id/chapters/:chapterId/sub/:subChapterId/edit',
        exact: true,
        component: InstructorSubChapterEdit,
    },

    // Account
    account: {
        path: 'account',
        exact: true,
        component: InstructorProfile,
    },
    account_edit: {
        path: 'account/edit',
        exact: true,
        component: InstructorProfileEdit,
    },

    // Discussions
    group_discussions: {
        path: 'discussions',
        exact: true,
        component: InstructorDiscussionsList,
    },
    group_discussion_details: {
        path: 'discussions/:id',
        exact: true,
        component: InstructorDiscussionDetails,
    },

    // Modules
    modules: {
        path: 'modules',
        exact: true,
        component: InstructorModuleList,
    },

    //Exams
    exams_list: {
        path: 'exams',
        exact: true,
        component: ExamsList,
    },
    exams_add: {
        path: 'exams/add',
        exact: true,
        component: AddExam,
    },
    exams_details: {
        path: 'exams/:id',
        exact: true,
        component: ExamDetails,
    },
    exams_edit: {
        path: 'exams/:id/edit',
        exact: true,
        component: EditExams,
    },
    exams_delete: {
        path: 'exams/:id/delete',
        exact: true,
        component: InstructorChapterDelete,
    },

}

export default instructor_urls;