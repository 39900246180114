import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {GET_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import LoaderComponent from "../../../../components/LoaderComponent";

class Topic extends Component {
    state = {
        topic: {}
    }

    componentDidMount() {
        this.setState({isLoading: true})
        makeRequest(GET_REQUEST, `${endpoints.group_discussions}${this.props.topic.id}`, {}, response => {
            this.setState({
                topic: response.data
            })
        }, null, () => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const {history} = this.props;
        const {topic, isLoading} = this.state;
        const {pathname} = this.props.location;
        const occupants = topic?.supervisors?.length + topic?.students?.length;
        return (
            isLoading ? <div className="text-center">
                    <LoaderComponent/>
                </div> :
                <div className="d-flex div-button-white border-bottom flex-wrap align-items-center">
                    <div onClick={e => {
                        e.preventDefault();
                        history.push(`${pathname}/${topic.id}`);
                    }} className="p-3 flex-grow-1">
                        <h5 className="font-weight-bold mb-2">{topic?.title}</h5>
                        <p className="small mb-3 font-weight-light"> {occupants} {occupants === 1 ? "Participant" : "Participants"}</p>
                        <p style={{color: "var(--primary-color)"}}
                           className="small font-weight-bold"> Add a comment</p>
                    </div>
                    {
                        topic?.url ? <button onClick={() => {
                            window.open(topic?.url);
                        }} className="button active mr-2">
                            Zoom
                        </button> : null
                    }
                </div>

        );
    }
}

export default Topic;