import React, {Component} from 'react';
import placeHolder from "../../../assets/images/lcpb.png";
import {makeRequest} from "../../../helpers/network_utils";
import {PATCH_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {asyncShowAlert} from "../../../helpers/helper_functions";

class InstructorCommentReply extends Component {
    state = {
        editMode: false,
        loading: false,
        errors: {},
    }

    handleSubmitEditedReply = event => {
        event.preventDefault();
        const {reply} = this.props;
        const {reply_text} = this.state;

        this.setState({loading: true});

        makeRequest(
            PATCH_REQUEST,
            `${endpoints.comment_replies}${reply.id}/`,
            {reply: reply.id, reply_text},
            _ => {
                this.props.refreshDiscussion();
                this.setState({editMode: false});
            },
            error => {
                if (error.response) {
                    this.setState({errors: error.response.data});
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
            },
            () => {
                this.setState({loading: false});
            }
        )
    }

    render() {
        const {reply, user} = this.props;
        const {editMode, loading, errors} = this.state;

        return (
            <div className="row" key={reply.id}>
                <div className="col-2">
                    <img src={placeHolder} alt="user" className="w-100 shadow-sm"/>
                </div>
                <div className="col-10">
                    <div className="card">
                        <div className="card-body">
                            {editMode
                                ?
                                <form onSubmit={this.handleSubmitEditedReply}>
                                    <div className="form-group">
                                        <label>Comment:</label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            onChange={event => this.setState({reply_text: event.target.value})}
                                            required>
                                            {reply.reply_text}
                                        </textarea>
                                        {errors.reply_text && (
                                            <p className="small text-danger mb-0">{errors.reply_text[0]}</p>
                                        )}
                                    </div>
                                    <div className="text-right">
                                        <button
                                            onClick={() => this.setState({editMode: !editMode})}
                                            type="button"
                                            className="btn btn-light btn-sm mr-2">
                                            Cancel
                                        </button>
                                        <button
                                            disabled={loading}
                                            type="submit"
                                            className="btn btn-primary btn-sm">
                                            {loading ? "Loading" : "Save Changes"}
                                        </button>
                                    </div>
                                </form>
                                :
                                <p className="mb-0">{reply.reply_text}</p>
                            }
                        </div>
                        <div className="card-footer">
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 small text-muted">
                                    {reply.user}, {new Date(reply.created_on).toLocaleString()}
                                </p>
                                <p className="mb-0 small text-muted">
                                    {reply.user === `${user.first_name} ${user.last_name}` && (
                                        <button
                                            onClick={() => this.setState({editMode: !editMode})}
                                            className="btn btn-link btn-sm">
                                            Edit
                                        </button>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr className="my-4"/>
                </div>
            </div>
        );
    }
}

export default InstructorCommentReply;