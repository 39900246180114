import {FETCH_COURSES, LOADED_COURSES, LOADING_COURSES} from "./actions";

const coursesState = {
    courses: null,
    isLoading: false
};

export default (state = coursesState, action) => {
    switch (action.type) {
        case FETCH_COURSES:
            return {...state, courses: action.courses, isLoading: false};
        case LOADING_COURSES:
            return {...state, isLoading: true};
        case LOADED_COURSES:
            return {...state, isLoading: false};
        default:
            return state;
    }
};