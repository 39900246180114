import React from "react";

const colors = [
    "#FFDAB9",
    "#00FF00",
    "#4169E1",
    "#FF6347",
    "#000080",
    "#3CB371",
    "#FFA07A",
    "#20B2AA",
    "#EE82EE",
    "#DC143C",
    "#F4A460"
]


function Reply(props) {

    const reply = props.reply;
    const index = Math.floor((Math.random() * colors.length));
    return (
        <div>
            <div className="p-3 border-bottom div-button-white">
                <div className="d-flex flex-wrap align-items-center">
                    <div style={{backgroundColor: colors[index]}} className="rounded-circle p-4 mr-2">

                    </div>
                    <div className="d-flex flex-column flex-wrap">
                        <h6 className="font-weight-bold m-0 mt-1">{reply?.user}</h6>
                        <p className="font-weight-light small m-0">
                            {reply?.reply_text}
                        </p>
                        <p style={{color: "var(--primary-color)"}}
                           className="small m-0">{new Date(reply?.last_update).toLocaleString()}</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Reply;