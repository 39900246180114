import React, {Component} from 'react';
import {getUserObject} from "../../../helpers/login";
import LoaderComponent from "../../../components/LoaderComponent";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {fetchInstructorDiscussions} from "../../../redux/instructors/actions";


const mapStateToProps = state => ({
    discussions: state.instructorState.discussions,
});

const mapDispatchToProps = dispatch => ({
    fetchDiscussions: id => dispatch(fetchInstructorDiscussions(id)),
});

class InstructorDiscussionsList extends Component {
    componentDidMount() {
        const user = getUserObject()
        this.props.fetchDiscussions(user.id);
    }

    render() {
        const {discussions} = this.props;

        return (
            <div className="container-fluid">
                {discussions
                    ?
                    <div>
                        <div className="row py-4">
                            <div className="col-12">
                                <div className="d-flex">
                                    <h2 className="mb-0">Assigned Group Discussions</h2>
                                </div>
                                <hr/>
                            </div>

                            {discussions?.discussions_assigned?.map((discussion, index) => (
                                <div className="col-12" key={discussion.id}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="mb-0">
                                                {index + 1}.&nbsp;
                                                <Link to={`/instructors/discussions/${discussion.id}`}>
                                                    {discussion.title}
                                                </Link>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    :
                    <LoaderComponent/>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstructorDiscussionsList);