const $ = window.$;


$(document).ready(function () {
    const mainNavbar = $('#main_navbar');

    $(window).on('scroll', function () {
        if (!window.location.pathname.startsWith("/learn/modules")) {
            let currentScrollPosition = $(window).scrollTop();
            if (currentScrollPosition > 371) {
                if (!mainNavbar.hasClass('fixed-top')) {
                    mainNavbar.hide();
                    mainNavbar.addClass('fixed-top');
                    mainNavbar.slideDown();
                }

            } else {
                if (currentScrollPosition < 200)
                    if (mainNavbar.hasClass('fixed-top')) {
                        mainNavbar.removeClass('fixed-top');
                    }


            }
        }

    })
});