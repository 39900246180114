import React, {Component} from 'react';
import LoaderComponent from "../../../components/LoaderComponent";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import SingleExamSmall from "./single_exam_small";

class AllExams extends Component {
    state = {
        exams: [],
        isLoading: false
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        });
        makeRequest(
            GET_REQUEST,
            `${endpoints.tests}?is_submitted=true`,
            {},
            response => {
                this.setState({
                    exams: response.data
                })
            },
            null,
            () => {
                this.setState({
                    isLoading: false
                })
            });
    }

    render() {
        const {exams, isLoading} = this.state;

        return (
            <div className="container pt-5">
                {isLoading ?
                    <LoaderComponent/> :
                    <div className="row">
                        {exams.map(exam => (
                            <SingleExamSmall key={exam.id} exam={exam}/>
                        ))}
                        {exams.length === 0 && (
                            <div className="col-12">
                                <p className="text-center">No exams added yet.</p>
                            </div>
                        )}
                    </div>
                }
            </div>
        );
    }
}

export default AllExams;