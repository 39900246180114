import React from "react";
import {Route, Switch} from "react-router";
import {studentRoutes} from "../../../routes/routes";
import NotFound from "../../../main/404";

function Main() {
    return (
        <Switch>
            {studentRoutes.map((route, index) => (
                <Route
                    key={index}
                    path={`/students/${route.path}`}
                    exact={route.exact}
                    component={(props) =>
                        <route.component location={props.location}
                                         history={props.history}
                                         match={props.match}/>
                    }
                />
            ))}
            <NotFound/>
        </Switch>
    )
}

export default Main;