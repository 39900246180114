import React from "react";


function Facilitator(props) {
    return (
        <div className="col-xl-3 col-md-6 mb-4">
            <div style={{overflow: "hidden"}} className="card h-100">
                <div className="card-img">
                    {props.src ?
                        <iframe allowFullScreen="allowfullscreen" className="iframe-holder border-0" src={props.src}
                                title={"Video"}/> :
                        <div className="bg-primary iframe-holder"/>
                    }
                </div>
                <div className="card-body">
                    <h5 className="font-weight-bold">
                        {props.name}
                    </h5>
                </div>
            </div>
        </div>
    )
}

export default Facilitator;