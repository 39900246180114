import React, {Component} from 'react';
import {makeRequest, scheduleTokenRefreshes} from "../../helpers/network_utils";
import {getUserObject, isUserLoggedIn, saveRefreshToken, saveToken, setUserObject} from "../../helpers/login";
import {POST_REQUEST} from "../../values/globals";
import homeBg from '../../assets/images/home_bg.jpg';
import allLogos from '../../assets/images/all-logos.png';
import SingleModuleMain from "../modules/single_module_main";
import {getCourses} from "../../redux/courses/actions";
import {connect} from "react-redux";
import Facilitator from "../facilitators/facilitator";
import GalleryItem from "../gallery/gallery_item";

const mapStateToProps = state => ({
    modules: state.coursesState.courses,
});

const mapDispatchToProps = dispatch => ({
    fetchCourses: () => dispatch(getCourses()),
});

class Home extends Component {
    state = {
        error: null,
        response: null,
        email: 'email',
        password: 'password',
        isLoading: false
    };

    componentDidMount() {
        if (this.props.modules == null)
            this.props.fetchCourses();
    }

    submitForm = event => {
        event.preventDefault();

        this.setState({isLoading: true});

        makeRequest(
            POST_REQUEST, "/auth/jwt/create/",
            {
                email: this.state.email,
                password: this.state.password
            },
            (response) => {
                let {access, refresh} = response.data;
                saveToken(access);
                saveRefreshToken(refresh);

                this.setState({
                    response: null,
                    error: null
                });

                //scheduleTokenRefreshes();
                setUserObject(
                    () => {
                    },
                    () => {
                        scheduleTokenRefreshes();
                        this.setState({
                            isLoading: false
                        });
                        const user = getUserObject();

                        //If user is logging in for the first time, take them to register.
                        if (user.is_first_time_user) {
                            this.props.history.push("/register")
                            return;
                        }

                        if (user.role === "instructor") {
                            window.location.href = "/instructors/account/";
                        } else if (user.role === "student") {
                            window.location.href = "/students/account/";
                        } else if (user.role === "admin") {
                            window.location.href = "/modules";
                        } else {
                            window.location.href = "/modules";
                        }
                    });
            },
            error => {
                this.setState({
                    error: error,
                    response: null,
                    isLoading: false
                });
            }
        )
    };


    render() {
        const {history} = this.props;
        const {modules} = this.props;

        let message;
        const {error, response, isLoading} = this.state;
        if (error) {
            message = "The credentials provided are invalid.";
            //message = parseErrorResponse(error);
        } else if (response != null) {
            message = "";
            Object.values(response.data).forEach(function (item) {
                message += item + " ";
            });
        }

        return (
            <div>
                <div style={{
                    backgroundImage: `url(${homeBg})`,
                    backgroundSize: "cover",
                    position: "relative",
                    color: "var(--text-color-light)"
                }}
                     className="row min-vh-75 mx-auto no-gutters align-items-center">
                    <div className="overlay"/>
                    <div className="col-lg-7 mt-5 px-5">
                        <h1 style={{fontSize: "2.5rem", lineHeight: "1.7"}} className="mb-lg-5 display-5 ml-md-5">
                            Welcome to Lawyers <br/> Capacity Building Program
                        </h1>
                    </div>
                    {!isUserLoggedIn()
                        ?
                        <div className="col-lg-5 mt-lg-5 px-lg-5">
                            <div style={{backgroundColor: "rgba(0,0,0,.5)"}} className="p-5 rounded-lg">
                                <h3 className="text-center font-weight-bold text-uppercase mb-5">SIGN IN</h3>
                                <div style={message ? {display: 'block'} : {display: 'none'}}
                                     className="alert alert-danger alert-dismissible fade show mt-5" role="alert">
                                    <strong>Error!</strong> {message}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form onSubmit={this.submitForm}>
                                    <div className="form-group">
                                        <input type="email" className="form-control"
                                               onChange={event => this.setState({email: event.target.value})}
                                               aria-describedby="emailHelp" placeholder="Email"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control"
                                               onChange={event => this.setState({password: event.target.value})}
                                               aria-describedby="emailHelp" placeholder="Password"/>
                                    </div>
                                    <p className="pb-4 font-weight-light">
                            <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={e => {
                                e.preventDefault();
                                history.push("/reset");
                            }} className="ml-1">Forgot Password?</span>
                                    </p>
                                    <button
                                        className="button btn-cs-white active d-flex align-items-center justify-content-center w-100">
                                        LOGIN

                                        {isLoading ?
                                            <div className="spinner-border text-white spinner-border-sm m-2"
                                                 role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : null
                                        }
                                    </button>
                                </form>
                            </div>
                        </div> : null
                    }
                </div>
                <div className="text-center py-2">
                    <img src={allLogos} alt="partners" className="w-75 px-5"/>
                </div>
                <div className="col-12 bg-light">
                    <div className="p-lg-5 pt-5 mx-4">
                        <h3 style={{color: "var(--text-color)"}}
                            className="cs-border-bottom-primary text-uppercase p-3 text-center mb-4">
                            ABOUT THE LAWYERS CAPACITY BUILDING PROGRAM </h3>
                        <p className="text-justify">
                            This is a capacity building programme to build and strengthen capacity of public and private
                            sector, academia and civil society lawyers from Kenya and Uganda. The training aims to
                            enhance their knowledge consider and integrate ecological, environmental, and social
                            considerations during negotiation and drafting of upstream extractives contracts.
                        </p>
                        <p className="text-justify">
                            The training conducted entirely online through this custom-made Learning Management
                            System and videoconferencing, focuses on the upstream segment of mining, oil and gas
                            contract development and negotiation.
                        </p>
                        <p className="text-justify">
                            A team from the University of Nairobi and Makerere University have prepared content to be
                            delivered during the training programme. The programme is delivered carefully selected
                            techniques including short framing lectures, small group discussions, world café’s,
                            simulations, and practicum. Two cohorts of lawyers will participate in the programme,
                            between 14 – 29 September 2020, with each training running over six (6) days. An outcome
                            of the programme will be a practical toolkit, which will be available online, and can build
                            the
                            capacity of other lawyers that will not participate in this programme.
                        </p>
                    </div>
                </div>
                <div className="container pt-5 pb-5">
                    <h3 className="cs-border-bottom-primary text-uppercase p-3 mb-4 text-center">
                        Welcome Note
                    </h3>
                    <div className="row mx-auto">
                        <Facilitator name={"Objectives of the training"}
                                     src={"https://www.youtube.com/embed/XNlS42deWgc"}/>
                        <Facilitator name={"Welcome Remarks UNES MD Seith Abek"}
                                     src={"https://www.youtube.com/embed/TWnBpShWr6k"}/>
                        <Facilitator name={"Welcome Remarks Makerere University Prof. Mbazira"}
                                     src={"https://www.youtube.com/embed/OypBK57IbIQ"}/>
                        <Facilitator name={"Welcome Remarks from ALSF CEO Stephen Karangizi"}
                                     src={"https://www.youtube.com/embed/kRnRf6ROvzY"}/>
                    </div>

                    <h3 className="cs-border-bottom-primary text-uppercase p-3 mb-4 text-center mt-5">
                        Vice Chancellor's Remarks
                    </h3>
                    <div className="row mx-auto justify-content-center">
                        <GalleryItem name={"Vice Chancellor's opening remarks"}
                                     src={"https://www.youtube.com/embed/jSHSAPY5d68"}/>
                    </div>
                </div>
                <div className="col-12 text-center mt-4">
                    <div className="p-2">
                        <h3 className="cs-border-bottom-primary text-uppercase p-3 mb-4">
                            Learning Material
                        </h3>
                        <div className="container">
                            <div className="row mx-auto">
                                {modules && modules.slice(0, 6).map(module => (
                                    <SingleModuleMain key={module.id} module={module}/>
                                ))}
                            </div>
                        </div>
                        <button onClick={() => {
                            history.push("/modules")
                        }} className="button active mt-5">
                            View Courses
                        </button>
                    </div>
                </div>
                <div className="container pt-1 pb-5">
                    <div className="pt-5 p-2">
                        <h3 className="cs-border-bottom-primary text-uppercase p-3 mb-4 text-center">
                            Socials
                        </h3>
                    </div>
                    <div className="row mx-auto">
                        <div className="col-md-6 mb-5">
                            <div style={{height: "60vh"}} className="card">
                                <a className="twitter-timeline" data-height="100%"
                                   href="https://twitter.com/UoNLawSch?ref_src=twsrc%5Etfw">Tweets
                                    by UoNLawSch</a>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div style={{height: "60vh"}} className="card">
                                <a className="twitter-timeline" data-height="100%"
                                   href="https://twitter.com/uonbi?ref_src=twsrc%5Etfw">Tweets by uonbi</a>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-auto justify-content-between">
                        <div className="col-lg-4 col-md-6 mb-3">
                            <div style={{height: "60vh", overflow: "hidden"}}>
                                <div style={{width: "100%", height: "100%"}} className="d-flex">
                                    <iframe
                                        title={"Facebook"}
                                        id="fb-column"
                                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2Fuonbi.ac.ke&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                        className="flex-grow-1"
                                        width="340" height="500"
                                        style={{border: "none", overflow: "hidden"}} scrolling="no"
                                        frameBorder="0" allowtransparency="true" allow="encrypted-media"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-3">
                            <div style={{height: "60vh", overflow: "hidden"}}>
                                <div style={{width: "100%", height: "100%"}} className="d-flex">
                                    <iframe
                                        title={"Facebook"}
                                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2FUoN-school-of-Law-358693194468882&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                        width="340" height="500" style={{border: "none", overflow: "hidden"}}
                                        scrolling="no"
                                        frameBorder="0" allowtransparency="true" allow="encrypted-media"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-3">
                            <div style={{height: "60vh", overflow: "hidden"}}>
                                <div style={{width: "100%", height: "100%"}} className="d-flex">
                              consol      <iframe
                                        title={"Facebook"}
                                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2FAfrican-Legal-Support-Facility-Facilit%25C3%25A9-africaine-de-soutien-juridique-187286088094539&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                        width="340" height="500" style={{border: "none", overflow: "hidden"}}
                                        scrolling="no"
                                        frameBorder="0" allowtransparency="true" allow="encrypted-media"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);