import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST, PATCH_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {asyncShowAlert, parseErrorResponse} from "../../../../helpers/helper_functions";
import Choice from "./choices/choice";
import AddChoiceModal from "./choices/add_choice_modal";

class Question extends Component {

    state = {
        loading: false,
        choices: [],
        text: "",
        is_editing: false
    }

    componentDidMount() {
        this.setState({
            loading: true
        })
        this.getChoices();
    }

    getChoices = () => {
        makeRequest(
            GET_REQUEST,
            `${endpoints.choices}?question=${this.props.question.id}`,
            {},
            response => {
                this.setState({
                    choices: response.data
                })
            },
            error => {
                asyncShowAlert("error", error.message).finally();
            },
            () => {
                this.setState({loading: false});
            }
        )
    }

    handleDelete = () => {
        this.setState({loading: true, is_editing: false});
        const {callback} = this.props;
        makeRequest(
            DELETE_REQUEST,
            `${endpoints.questions}${this.props.question.id}/`,
            {
                text: this.state.text
            },
            () => {
                if (callback) callback();
            },
            error => {
                asyncShowAlert('error', parseErrorResponse(error)).then();
            }, () => {
                this.setState({
                    loading: false
                })
            }
        )
    }

    handleEdit = () => {
        this.setState({loading: true});
        const {callback} = this.props;
        makeRequest(
            PATCH_REQUEST,
            `${endpoints.questions}${this.props.question.id}/`,
            {text: this.state.text},
            () => {
                if (callback) callback();
            },
            error => {
                asyncShowAlert('error', parseErrorResponse(error)).then();
            }, () => {
                this.setState({
                    loading: false,
                    is_editing: false
                })
            }
        )
    }


    render() {
        const {question, index} = this.props;
        const {choices, loading, is_editing} = this.state;
        let {text} = this.state;
        if (!text) text = question.text;
        return (
            loading ? <div className="card mb-3">
                    <div className="card-header bg-white">
                        Loading...
                    </div>
                </div> :
                <div className="card mb-3">
                    <AddChoiceModal id={question.id} callback={this.getChoices}/>
                    <div className="card-header bg-white">
                        <div className="d-flex justify-content-between flex-column flex-md-row">
                            {
                                is_editing ?
                                    <div className="mb-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                value={text}
                                                className="form-control mb-3 mr-2"
                                                onChange={e => this.setState({text: e.target.value})}
                                                required/>
                                        </div>
                                        <div className="d-flex">
                                            <button className="btn btn-primary btn-sm mr-2 flex-grow-1"
                                                    data-toggle="modal"
                                                    onClick={this.handleEdit}>
                                                Save
                                            </button>
                                            <button className="btn btn-danger btn-sm flex-grow-1" data-toggle="modal"
                                                    onClick={() => {
                                                        this.setState({
                                                            is_editing: false
                                                        })
                                                    }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div> :
                                    <h4>{index + 1}. {question.text}</h4>
                            }

                            <div className="d-flex flex-column">
                                <button className="btn btn-primary btn-sm mb-3" data-toggle="modal"
                                        data-target={`#addChoiceModal${question.id}`}>
                                    Add a Choice
                                </button>
                                <button className="btn btn-danger btn-sm mb-3" onClick={this.handleDelete}>
                                    Delete Question
                                </button>
                                <button className="btn btn-info btn-sm" onClick={() => {
                                    this.setState({
                                        is_editing: true
                                    })
                                }}>
                                    Edit
                                </button>
                            </div>

                        </div>

                    </div>
                    <div className="card-body">
                        {
                            choices.map(choice =>
                                <Choice key={choice.id} choice={choice} callback={this.getChoices}/>
                            )
                        }
                    </div>
                </div>
        );
    }
}

export default Question;