import {
    FETCH_INSTRUCTOR_COURSES,
    FETCH_INSTRUCTOR_DISCUSSIONS,
} from "./actions";

const instructorState = {
    courses: null,
    discussions: null,
};

export default (state = instructorState, action) => {
    if (action.type === FETCH_INSTRUCTOR_COURSES) {
        return {...state, courses: action.payload};
    }
    if (action.type === FETCH_INSTRUCTOR_DISCUSSIONS) {
        return {...state, discussions: action.payload};
    }

    return state;
}