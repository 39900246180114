import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {asyncShowAlert} from "../../../helpers/helper_functions";
import ReactPlayer from "react-player";
import ChapterResources from "../chapter_resources";

const parse = require('html-react-parser');

class ChapterDetails extends Component {
    state = {
        chapter: this.props.chapter,
    }

    componentDidMount() {
        const {id} = this.props.chapter;
        let url = this.props.isChapter ? `${endpoints.chapters}${id}/` : `${endpoints.sub_chapters}${id}/`;
        makeRequest(
            GET_REQUEST,
            url
            ,
            {},
            response => {
                this.setState({chapter: response.data});
            },
            error => {
                if (error.response) {
                    asyncShowAlert("error", error.response.statusText).finally();
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
            }
        )
    }

    render() {
        const {chapter} = this.state;

        return (
            <div>
                {
                    chapter?.youtube_link ?
                        <div className="bg-white rounded p-3 ml-lg-3 mt-2 mb-4">
                            <div className="video-holder mx-auto">
                                <ReactPlayer
                                    controls={true}
                                    width={"100%"}
                                    height={"100%"}
                                    url={chapter.youtube_link}/>
                            </div>
                        </div> : null
                }
                {/*<div dangerouslySetInnerHTML={{__html: chapter?.content || ""}}/>*/}
                {parse(chapter?.content || "")}
                <ChapterResources module={chapter}/>
            </div>
        );
    }
}

export default ChapterDetails;