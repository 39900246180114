import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import LoaderComponent from "../../../components/LoaderComponent";
import {getExamSubmitted} from "../../../helpers/helper_functions";

const parse = require('html-react-parser');

class SingleExam extends Component {

    state = {
        loading: false,
        errors: {},
        exam: null,
    }

    componentDidMount() {
        const {id} = this.props.match.params;

        this.setState({loading: true});

        makeRequest(
            GET_REQUEST,
            `${endpoints.tests}${id}`,
            {},
            response => {
                this.setState({exam: response.data});
            },
            () => {
                this.setState({error: true});
            }, () => {
                this.setState({
                    loading: false
                })
            }
        );
    }


    render() {
        const {loading, exam} = this.state;
        const {history} = this.props;
        const {id} = this.props.match.params;
        const examSubmitted = getExamSubmitted();
        const now = new Date();
        const startTime = new Date(exam?.starts_at);
        const shouldStart = now >= startTime;

        return (
            <div className="container-fluid">
                {loading
                    ? <LoaderComponent/> :
                    <div className="row py-4 mb-4 justify-content-center">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h2 className="mb-0">{exam?.name ?? "-"}</h2>
                                    <p className="m-0">Duration: {exam?.duration ?? "-"}</p>
                                </div>
                            </div>
                            <hr/>
                        </div>
                        <div className="col-lg-10">
                            <div className="card">
                                <div className="card-header bg-white p-5">
                                    <h3 className="font-weight-bold">Please Read the Instructions Carefully.</h3>
                                </div>
                                <div className="card-body">
                                    {
                                        parse(exam?.instructions ?? "-")
                                    }
                                </div>
                                <hr/>
                                <div className="card-body">
                                    {
                                        !examSubmitted.submitted ?
                                            <div className="row justify-content-center">
                                                <span className="badge-warning p-3 rounded">
                                                    You already have an exam on-going. Please complete that exam first.

                                                    <div className="text-center">
                                                        <button onClick={() => {
                                                            history.push(examSubmitted.link)
                                                        }} className="btn btn-sm btn-info">
                                                            Go To Exam
                                                        </button>
                                                    </div>
                                                </span>
                                            </div>
                                            :
                                            !shouldStart ?
                                                <div className="row justify-content-center">
                                                    <span className="badge-warning p-3 rounded">
                                                        The exam is not yet opened. Please wait
                                                        until: {startTime.toLocaleString()} to be able to
                                                        start this exam.
                                                        <div className="text-center">
                                                            <button onClick={() => {
                                                                history.push(examSubmitted.link)
                                                            }} className="btn btn-sm btn-info">
                                                                Go To Exam
                                                            </button>
                                                        </div>
                                                    </span>
                                                </div> :
                                                <button
                                                    onClick={() => this.props.history.push(`/students/exams/${id}/start`)}
                                                    className="btn btn-primary w-100">
                                                    <span className="d-flex justify-content-center">
                                                        <span className="material-icons">play_arrow</span>
                                                        &nbsp;
                                                        <span>start</span>
                                                    </span>
                                                </button>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default SingleExam;