import React, {Component} from 'react';
import {Route, Switch} from "react-router";
import {instructorRoutes} from "../../routes/routes";
import NotFound from "../../main/404";


class InstructorWrapper extends Component {
    render() {
        return (
            <div className="container-fluid">
                <Switch>
                    {instructorRoutes.map((route, index) => (
                        <Route
                            key={index}
                            path={`/instructors/${route.path}`}
                            exact={route.exact}
                            component={(props) =>
                                <route.component
                                    location={props.location}
                                    history={props.history}
                                    match={props.match}/>
                            }
                        />
                    ))}
                    <NotFound/>
                </Switch>
            </div>
        );
    }
}

export default InstructorWrapper;