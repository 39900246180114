import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {POST_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {asyncShowAlert} from "../../../../helpers/helper_functions";

class InstructorChapterAdd extends Component {
    state = {
        loading: false,
        errors: {},
    }

    componentDidMount() {
        window.$("#moduleDescription").summernote({
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video']],
                ['view', ['fullscreen', 'codeview', 'help']]
            ],
            height: 300,
            callbacks: {
                onChange: content => {
                    this.setState({content});
                }
            }
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({loading: true});

        const {id} = this.props.module;
        const {title, content, youtube_link, photo} = this.state;

        const formData = new FormData();
        if (title) formData.append("title", title);
        if (content) formData.append("content", content);
        if (youtube_link) formData.append("youtube_link", youtube_link);
        if (photo) formData.append("photo", photo);
        formData.append("course", id);

        makeRequest(
            POST_REQUEST,
            endpoints.chapters,
            formData,
            _ => {
                window.$("#addModuleChapter").modal("hide");
                asyncShowAlert("success", "Chapter added successfully").finally();
                this.props.refresh();
            },
            error => {
                if (error.response) {
                    this.setState({errors: error.response.data});
                }
            },
            () => {
                this.setState({loading: false});
            }
        );
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    render() {
        const {loading, errors} = this.state;

        return (
            <div className="modal fade" id="addModuleChapter">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h3 className="text-center mb-4">Add Chapter</h3>
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label>Chapter Title:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        autoFocus
                                        onChange={event => this.setState({title: event.target.value})}
                                        required/>
                                    {errors.title && (
                                        <p className="mb-0 small text-danger">{errors.title[0]}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Chapter Content:</label>
                                    <textarea id="moduleDescription" required/>
                                    {errors.content && (
                                        <p className="mb-0 small text-danger">{errors.content[0]}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>YouTube Link:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={event => this.setState({youtube_link: event.target.value})}/>
                                    {errors.youtube_link && (
                                        <p className="mb-0 small text-danger">{errors.youtube_link[0]}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Photo:</label>
                                    <input
                                        type="file"
                                        className="form-control form-control-file"
                                        onChange={event => this.setState({photo: event.target.files[0]})}
                                    />
                                    {errors.photo && (
                                        <p className="mb-0 small text-danger">{errors.photo[0]}</p>
                                    )}
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-primary" disabled={loading}>
                                        {loading ? "Loading..." : "Submit Chapter"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstructorChapterAdd;