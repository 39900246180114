import React from 'react';
import CustomNavLink from "../components/nav_link";
import NavButton from "../components/nav_button";
import '../assets/js/navbar';
import {getUserObject, isUserLoggedIn} from "../helpers/login";
import {Link} from "react-router-dom";
import IconButton from "../components/IconButton";

function NavBar() {
    const user = getUserObject();

    return <nav id={"main_navbar"} className="navbar shadow-sm navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid pb-1">
            <Link to="/" className="navbar-brand">
                <h1 className="mb-0">LCBP</h1>
            </Link>
            <button className="button navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"/>
            </button>
            {isUserLoggedIn() ?
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {user.role === "student" && (
                        <ul className="navbar-nav mr-auto ml-auto pb-2">
                            <li className="nav-item">
                                <CustomNavLink to="/home">Home</CustomNavLink>
                            </li>
                            <li className="nav-item">
                                <CustomNavLink to="/modules">Modules</CustomNavLink>
                            </li>
                            <li className="nav-item">
                                <CustomNavLink to="/students/exams">Exams</CustomNavLink>
                            </li>
                            <li className="nav-item">
                                <CustomNavLink to="/partners">Partners</CustomNavLink>
                            </li>
                            <li className="nav-item">
                                <CustomNavLink to="/facilitators">Facilitators</CustomNavLink>
                            </li>
                            <li className="nav-item">
                                <CustomNavLink to="/contact-us">Contact</CustomNavLink>
                            </li>
                        </ul>
                    )}
                    {user.role === "instructor" && (
                        <ul className="navbar-nav mr-auto ml-auto pb-2">
                            <li className="nav-item">
                                <CustomNavLink to="/instructors/modules">My Modules</CustomNavLink>
                            </li>
                            <li className="nav-item">
                                <CustomNavLink to="/instructors/exams">My Exams</CustomNavLink>
                            </li>
                            <li className="nav-item">
                                <CustomNavLink to="/instructors/discussions">Discussions</CustomNavLink>
                            </li>
                        </ul>
                    )}
                    <div className="d-flex flex-md-row flex-column align-items-start">
                        <NavButton to={`/${user.role === "student" ? "students" : "instructors"}/account`}>
                            My Account
                        </NavButton>
                        &nbsp;
                        <NavButton to="/logout">
                            Sign Out
                        </NavButton>
                    </div>
                </div>
                :
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto ml-auto pb-2">
                        <li className="nav-item">
                            <CustomNavLink to="/home">Home</CustomNavLink>
                        </li>
                        <li className="nav-item">
                            <CustomNavLink to="/modules">Modules</CustomNavLink>
                        </li>
                        <li className="nav-item">
                            <CustomNavLink to="/partners">Partners</CustomNavLink>
                        </li>
                        <li className="nav-item">
                            <CustomNavLink to="/facilitators">Facilitators</CustomNavLink>
                        </li>
                        <li className="nav-item">
                            <CustomNavLink to="/contact-us">Contact</CustomNavLink>
                        </li>
                    </ul>
                    <div className="d-flex flex-md-row flex-column align-items-start">
                        <Link to="/login">
                            <IconButton text="Log In" icon="login" theme="outline-primary"/>
                        </Link>
                    </div>
                </div>
            }
        </div>
    </nav>
}

export default NavBar;