import React from "react";
import NavBar from "./navbar";
import {Route, Switch} from "react-router";
import {routes} from '../routes/routes';
import Footer from "./footer";
import NotFound from "./404";

function Main() {
    return (
        <div>
            <NavBar/>
            <div>
                <div className="min-vh-50">
                    <Switch>
                        {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                children={({location, history, match}) =>
                                    <route.component location={location}
                                                     history={history}
                                                     match={match}/>
                                }
                            />
                        ))}
                        <NotFound/>
                    </Switch>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Main;