import React, {Component} from 'react';
import {makeRequest} from "../../../../../helpers/network_utils";
import {POST_REQUEST} from "../../../../../values/globals";
import endpoints from "../../../../../routes/endpoints";

class AddChoiceModal extends Component {

    state = {
        loading: false,
        choice: "",
        errors: {}
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({loading: true, errors: {}});
        const {id, callback} = this.props;
        const {choice} = this.state;

        const formData = new FormData();

        if (choice) formData.append("title", choice);
        if (id) formData.append("question", id);
        this.setState({
            loading: true
        })
        makeRequest(
            POST_REQUEST,
            `${endpoints.choices}`,
            formData,
            () => {
                this.setState({choice: ""});
                if (callback) callback();
                window.$(`#addChoiceModal${id}`).modal('toggle');
                window.$('body').removeClass('modal-open');
                window.$('.modal-backdrop').remove();
            },
            error => {
                if (error.response) {
                    this.setState({errors: error.response.data, loading: false})
                }
            }, () => {
                this.setState({
                    loading: false
                })
            }
        )
    }

    render() {
        const {errors, loading, choice} = this.state;
        const {id} = this.props;
        return (
            <div className="modal fade" id={`addChoiceModal${id}`} tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add a Choice</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Enter a choice:</label>
                                    <input
                                        value={choice}
                                        type="text"
                                        className="form-control"
                                        onChange={event => this.setState({choice: event.target.value})}
                                        required/>
                                    {errors.title && (
                                        <p className="mb-0 small text-danger">{errors.title[0]}</p>
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button className="btn btn-primary" disabled={loading}>
                                    {loading ? "Loading..." : "Save"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddChoiceModal;