import React, {Component} from 'react';

class Choice extends Component {

    state = {
        loading: false,
        choice: this.props.choice
    }

    render() {
        const {choice} = this.state;
        const {loading} = this.state;
        const {selected, onClick} = this.props;
        return (
            loading ?
                <div className="d-flex align-items-center justify-content-center rounded-pill border p-3 m-2">
                    <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                : <div onClick={onClick}
                       className={`d-flex border-hover align-items-center justify-content-between rounded-pill border p-3 m-2 ${selected ? "bg-primary-light" : "bg-white"}`}>
                    <p className="m-0"> {choice.title}</p>
                </div>
        );
    }
}

export default Choice;