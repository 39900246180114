import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {asyncShowAlert} from "../../helpers/helper_functions";

export const FETCH_INSTRUCTOR_COURSES = "FETCH_INSTRUCTOR_COURSES";
export const FETCH_INSTRUCTOR_DISCUSSIONS = "FETCH_INSTRUCTOR_DISCUSSIONS";

const setInstructorCourses = payload => ({type: FETCH_INSTRUCTOR_COURSES, payload});
const setInstructorDiscussions = payload => ({type: FETCH_INSTRUCTOR_DISCUSSIONS, payload});

export const fetchInstructorCourses = (query = null) => {
    return dispatch => {

        makeRequest(
            GET_REQUEST,
            `${query ? endpoints.courses + query : endpoints.courses}`,
            {},
            response => {
                dispatch(setInstructorCourses(response.data));
            },
            error => {
                if (error.response) {
                    asyncShowAlert("error", error.response.statusText).finally();
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
            }
        )
    }
};

export const fetchInstructorDiscussions = instructorId => {
    return dispatch => {

        makeRequest(
            GET_REQUEST,
            endpoints.instructor_group_discussions(instructorId),
            {},
            response => {
                dispatch(setInstructorDiscussions(response.data));
            },
            error => {
                if (error.response) {
                    asyncShowAlert("error", error.response.statusText).finally();
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
            }
        )
    }
};


