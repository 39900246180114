import React, {Component} from 'react';
import {getUserObject, setUserObject} from "../../../../helpers/login";
import {makeRequest} from "../../../../helpers/network_utils";
import {PATCH_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import Swal from "sweetalert2";

const $ = window.$;

class ChangeProfileImage extends Component {

    state = {
        errors: {},
        isLoading: false
    }

    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            isLoading: true
        })
        let data = new FormData();

        data.append("photo", this.state.photo);
        makeRequest(PATCH_REQUEST, endpoints.current_user, data, () => {
            Swal.fire({
                title: 'Success!',
                text: 'Image Edited Successfully!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then()
            setUserObject(null, () => {
                window.location.reload();
                $("#changeImageModal").modal('hide');
            });
        }, (error) => {
            Swal.fire({
                title: 'Error!',
                text: 'We could not set your profile image. Please check your input for errors',
                icon: 'error',
                confirmButtonText: 'Ok'
            }).then(() => {
                this.setState({
                    errors: error.response.data
                })
            })
        }, () => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const {errors, isLoading} = this.state;
        const user = getUserObject();
        return (
            <div className="modal fade" id="changeImageModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="col-12">
                            <div>
                                <div className="pt-5 pb-4 pl-5 pr-5 d-flex justify-content-between">
                                    <h3 className="m-0">
                                        Select Image
                                    </h3>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <hr/>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label>Photo:</label>
                                        <input
                                            type="file"
                                            onChange={event => this.setState({photo: event.target.files[0]})}
                                            className="form-control form-control-file"
                                            required
                                        />
                                        {user.photo && (
                                            <p className="small">
                                                Current: <a href={user.photo}>{user.photo}</a>
                                            </p>
                                        )}
                                        {errors.photo && (
                                            <p className="small text-danger">{errors.photo[0]}</p>
                                        )}
                                    </div>
                                    <button disabled={isLoading} style={{
                                        padding: "0.625rem 1.25rem"
                                    }} className="button w-100 active">
                                        {isLoading ? "Loading..." : "Save"}
                                    </button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangeProfileImage;