import {withRouter} from "react-router";
import {Component} from "react";

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        const {location} = this.props;
        if (location !== prevProps.location && !location.pathname.startsWith("/students/account")) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop)