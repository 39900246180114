import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {GET_REQUEST, PATCH_REQUEST, POST_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {asyncShowAlert} from "../../../../helpers/helper_functions";
import Choice from "./choices/choice";
import {getUserObject} from "../../../../helpers/login";
import LoaderComponent from "../../../../components/LoaderComponent";

class Question extends Component {

    state = {
        loading: false,
        choices: [],
        currentIndex: null,
        student_answers: []
    }

    componentDidMount() {
        this.getChoices();
    }

    getMyAnswers = () => {
        const user = getUserObject();
        this.setState({
            loading: true
        })
        makeRequest(
            GET_REQUEST,
            `${endpoints.student_answers}?student=${user.id}&answer__question=${this.props.question.id}`,
            {},
            response => {
                const {choices} = this.state;
                if (response.data.length === 0) return;
                const answer = response.data[0].answer;
                for (let i = 0; i < choices.length; i++) {
                    if (choices[i].id === answer?.id) {
                        this.setState({
                            currentIndex: i
                        })
                        break;
                    }
                }
                if (answer) {
                    this.props.saveCompletedQuestion(this.props.question)
                }
                this.setState({
                    student_answers: response.data
                })
            },
            error => {
                asyncShowAlert("error", error.message).finally();
            },
            () => {
                this.setState({loading: false});
            }
        )

    }

    getChoices = () => {
        this.setState({
            loading: true
        })
        makeRequest(
            GET_REQUEST,
            `${endpoints.student_questions}${this.props.question.id}`,
            {},
            response => {
                this.setState({
                    choices: response.data.choices
                })
            },
            error => {
                asyncShowAlert("error", error.message).finally();
            },
            () => {
                this.setState({loading: false});
                this.getMyAnswers();
            }
        )
    }

    submitAnswer = (index) => {
        const {choices} = this.state;
        const choice = choices[index];
        const user = getUserObject();
        const {student_answers} = this.state;
        let method;
        let route;
        const answer = student_answers.length > 0 ? student_answers[0] : null;
        if (answer !== null) {
            method = PATCH_REQUEST;
            route = `${endpoints.student_answers}${answer.id}/`
        } else {
            method = POST_REQUEST;
            route = `${endpoints.student_answers}`;
        }
        makeRequest(
            method,
            route,
            {answer: choice.id, student: user.id},
            () => {

            },
            error => {
                asyncShowAlert("error", error.message).finally();
            },
            () => {
                this.setState({loading: false});
                this.getMyAnswers();
            }
        )
    }

    render() {
        const {question, index, currentQuestion} = this.props;
        const {choices, currentIndex, loading} = this.state;
        return (
            <div style={{display: currentQuestion ? "block" : "none"}}>
                <div className="card-header bg-white p-3">
                    <div className="d-flex justify-content-between">
                        <h4>{index + 1}. {question.text}</h4>
                    </div>
                </div>
                <div className="card-body">
                    {loading ? <LoaderComponent/> :
                        choices.map((choice, index) =>
                            <Choice onClick={() => {
                                this.submitAnswer(index)
                            }} selected={currentIndex === index} key={choice.id} choice={choice}
                                    callback={this.getChoices}/>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default Question;