import React from 'react';

function Choice(props) {

    const {choice, answer} = props;
    return (
        <div
            className={`d-flex border-hover align-items-center justify-content-between rounded-pill border p-3 m-2
             ${choice.is_correct ? "bg-success text-white" : choice?.id === answer?.id ? "bg-danger text-white" :
                "bg-white text-dark"}`}>
            <p className="m-0"> {choice.title}</p>
        </div>
    );
}

export default Choice;