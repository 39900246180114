import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST, POST_REQUEST, QUESTIONS_MARKED_FOR_REVIEW} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import LoaderComponent from "../../../components/LoaderComponent";
import Question from "./questions/question";
import IconButton from "../../../components/IconButton";
import Swal from "sweetalert2";
import {getExamSubmitted, parseTime, saveExamSubmitted} from "../../../helpers/helper_functions";
import {withAlert} from 'react-alert'
import {getUserObject} from "../../../helpers/login";

let x;
const $ = window.$;

class SingleExamInside extends Component {

    state = {
        loading: false,
        errors: {},
        questions: [],
        completedQuestions: [],
        questionsMarkedForReview: [],
        currentIndex: 0,
        countdown: null,
        countdownDate: null,
        exam: null,
    }

    componentDidMount() {
        const {location} = this.props;
        saveExamSubmitted(false, location.pathname);
        this.initExam();
        this.getQuestions();
        this.disableKeyboard();
        this.initQuestionsMarkedForReview();
    }

    initQuestionsMarkedForReview = () => {
        let questionsMarkedForReview = localStorage.getItem(QUESTIONS_MARKED_FOR_REVIEW) ?? "[]";

        let questionsMarkedForReviewList = JSON.parse(questionsMarkedForReview);
        this.setState({
            questionsMarkedForReview: questionsMarkedForReviewList
        })
    }

    initExam = () => {
        const {id} = this.props.match.params;
        const {history} = this.props;
        this.setState({loading: true});
        const user = getUserObject();

        //Check if user has already taken this test.
        makeRequest(GET_REQUEST, endpoints.student_taken_tests(user.id), {}, response => {
            const tests = response.data.taken_tests;
            for (let i = 0; i < tests.length; i++) {
                if (tests[i]?.test?.id === parseInt(id)) {
                    this.handleSubmitted();
                    history.push(`/students/exams/${tests[i]?.test?.id}/done`);
                    return;
                }
            }

        }, (error) => {
            console.log(error);
            this.setState({error: true});
        }, () => {
            this.initCountDown();
        })
    }

    initCountDown = () => {
        const {id} = this.props.match.params;
        makeRequest(
            GET_REQUEST,
            `${endpoints.tests}${id}`,
            {},
            response => {
                const exam = response.data;

                this.setState({exam: exam})

                this.getStartTime();

            },
            () => {
                this.setState({error: true});
            }, () => {
                this.setState({
                    loading: false
                })
            }
        );
    }

    getStartTime = () => {
        const {id} = this.props.match.params;
        const user = getUserObject();
        makeRequest(
            GET_REQUEST,
            `${endpoints.start_times}?student=${user.id}&test=${id}`,
            {test: id},
            response => {
                const {exam} = this.state;

                //get the actual real duration of this user.
                let tempTime = response?.data[0]?.created_on;
                let savedDuration = tempTime != null ? Date.parse(tempTime) : null;
                let now = new Date();
                let countdownDate = parseTime(exam.duration);
                if (savedDuration != null) {
                    savedDuration = new Date(savedDuration);
                    //get time passed (hours minutes seconds).
                    let passedTime = now.getTime() - savedDuration.getTime();

                    let seconds = Math.floor((passedTime / 1000) % 60),
                        minutes = Math.floor((passedTime / (1000 * 60)) % 60),
                        hours = Math.floor((passedTime / (1000 * 60 * 60)) % 24);

                    if (hours > countdownDate.getHours() && minutes > countdownDate.getMinutes() && seconds > countdownDate.getSeconds()) {
                        countdownDate.setSeconds(0);
                        countdownDate.setMinutes(0);
                        countdownDate.setHours(0);

                        //Submit automatically.
                        Swal.fire({
                            title: 'Sorry :-(',
                            text: 'You cannot attempt this exam since the exam duration has already elapsed.',
                            icon: 'info',
                            confirmButtonText: 'Ok'
                        }).then(() => {
                            this.handleSubmitWithoutPrompt();
                        })
                        return;
                    } else {
                        countdownDate.setSeconds(countdownDate.getSeconds() - seconds);
                        countdownDate.setMinutes(countdownDate.getMinutes() - minutes);
                        countdownDate.setHours(countdownDate.getHours() - hours);
                    }

                } else {
                    makeRequest(
                        POST_REQUEST,
                        `${endpoints.start_test}`,
                        {test: id},);
                }
                this.setState({exam: exam, countdownDate: new Date(countdownDate)});
                x = setInterval(() => {
                    let {exam, countdownDate} = this.state;
                    countdownDate = countdownDate ?? parseTime(exam.duration());

                    this.setState({
                        countdown: countdownDate.toLocaleTimeString()
                    })

                    if (countdownDate.getSeconds() <= 0 && countdownDate.getMinutes() <= 0 && countdownDate.getHours() <= 0) {
                        clearInterval(x);
                        this.handleSubmitWithoutPrompt();
                        return;
                    }
                    countdownDate.setSeconds(countdownDate.getSeconds() - 1);


                }, 1000)

            },
            () => {
                this.setState({error: true});
            }, () => {
                this.setState({
                    loading: false
                })
            }
        );

    }

    saveCompletedQuestion = (question) => {
        const completedQuestions = [...this.state.completedQuestions];
        if (question && !this.questionAlreadyCompleted(question)) {
            completedQuestions.push(question);
            this.setState({
                completedQuestions: completedQuestions
            })
        }

    }

    questionAlreadyCompleted = (question) => {
        const {completedQuestions} = this.state;
        for (let i = 0; i < completedQuestions.length; i++) {
            if (question?.id === completedQuestions[i].id)
                return true;
        }
        return false;
    }

    componentWillUnmount() {
        this.enableKeyboard();
        if (getExamSubmitted().submitted) return;
        const {alert, history} = this.props;
        const {id} = this.props.match.params;
        alert.show('You still have an exam going on. Click close to return to the exam. Also note that the countdown has not paused.', {
            type: 'info',
            timeout: 50000000,
            onClose: () => {
                history.push(`/students/exams/${id}/start`)
            }
        })
        clearInterval(x);
    }

    disableKeyboard = () => {
        $(document).bind("contextmenu", function () {
            return false;
        });
        $(document).bind("onkeydown", function () {
            return false;
        })
        /*document.onkeydown = function (e) {
            return false;
        }*/
    }

    enableKeyboard = () => {
        $(document).bind("onkeydown", function () {
            return true;
        })
    }

    getQuestions = () => {
        const {id} = this.props.match.params;
        this.setState({loading: true});
        makeRequest(
            GET_REQUEST,
            `${endpoints.questions}?test=${id}`,
            {},
            response => {
                this.setState({questions: response.data});
            },
            () => {
                this.setState({error: true});
            }, () => {
                this.setState({
                    loading: false
                })
            }
        );
    }

    next = () => {
        const {currentIndex, questions} = this.state;
        if (currentIndex >= questions.length - 1) return;
        this.setState({
            currentIndex: currentIndex + 1
        })
    }

    previous = () => {
        const {currentIndex} = this.state;
        if (currentIndex < 0) return;
        this.setState({
            currentIndex: currentIndex - 1
        })
    }

    markForReviewAndNext = () => {
        let {currentIndex, questionsMarkedForReview, questions} = this.state;
        if (this.markedForReview(questions[currentIndex])) return;
        const questionsMarkedForReviewCopy = [...questionsMarkedForReview];
        questionsMarkedForReviewCopy.push(questions[currentIndex]);
        this.setState({
            questionsMarkedForReview: questionsMarkedForReviewCopy
        })
        //set to local storage in case user refreshes the page.
        localStorage.setItem(QUESTIONS_MARKED_FOR_REVIEW, JSON.stringify(questionsMarkedForReviewCopy));
        this.next();
    }

    markedForReview = (question) => {
        const {questionsMarkedForReview} = this.state;
        for (let i = 0; i < questionsMarkedForReview.length; i++) {
            if (questionsMarkedForReview[i].id === question.id) return true;
        }
        return false;
    }

    handleSubmit = () => {
        const {questionsMarkedForReview} = this.state;

        if (questionsMarkedForReview.length > 0) {
            Swal.fire({
                title: 'You have questions marked for review. Proceed?',
                showCancelButton: true,
                confirmButtonText: `Yes`,
                cancelButtonText: `No`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Swal.fire('Saved!', '', 'success').then();

                    this.handleSubmitWithoutPrompt();
                }
            })
        } else {
            this.handleSubmitWithoutPrompt();
        }
    }


    handleSubmitted = () => {
        saveExamSubmitted(true, "");
        localStorage.removeItem(QUESTIONS_MARKED_FOR_REVIEW);
    }

    handleSubmitWithoutPrompt = () => {
        const {id} = this.props.match.params;
        const {history} = this.props;
        this.setState({loading: true})
        makeRequest(POST_REQUEST, `${endpoints.taken_tests}`, {
            test: id
        }, () => {
            this.handleSubmitted();
            history.push(`/students/exams/${id}/done`);
        }, () => {

        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {loading, exam, questions, currentIndex, completedQuestions, countdown} = this.state;
        return (
            loading
                ? <LoaderComponent/> :
                <div className="container">
                    <div className="row py-4 mb-4">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h2 className="mb-0">{exam?.name ?? "-"}</h2>
                                    <p className="m-0">Remaining Time: {countdown ?? "-"}</p>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                    <div className="row py-4 mb-4">
                        <div className="col-md-8 mb-3">

                            <div className="card ">
                                {questions.length === 0 ? <p className="p-5"> No questions here yet.</p> :
                                    questions.map((question, index) => <Question
                                        saveCompletedQuestion={question => this.saveCompletedQuestion(question)}
                                        index={currentIndex}
                                        currentQuestion={currentIndex === index}
                                        key={question.id}
                                        question={question}/>
                                    )
                                }
                                <div className="card-body">
                                    <div className="d-flex mb-3 justify-content-between">
                                        <div className="mr-3">
                                            <IconButton
                                                onClick={this.previous}
                                                icon="chevron_left"
                                                text="Previous"
                                                theme="primary"
                                                size="sm"/>
                                        </div>
                                        <ExamButton
                                            onClick={this.next}
                                            icon="chevron_right"
                                            text="Next"
                                        />
                                    </div>

                                    <div className="d-flex mb-3 justify-content-between">
                                        <div className="mr-3">
                                            <IconButton
                                                icon="check_circle_outline"
                                                text="Mark For Review and Next"
                                                theme="warning"
                                                onClick={this.markForReviewAndNext}
                                                size="sm"/>
                                        </div>
                                        {
                                            completedQuestions.length === questions.length ?
                                                <IconButton
                                                    icon="subdirectory_arrow_right"
                                                    text="Submit Test"
                                                    theme="success"
                                                    onClick={this.handleSubmit}
                                                    size="sm"/> : null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div style={{overflow: "auto"}} className="card h-100">
                                <div className="card-header bg-white">
                                    <h4>Questions Palette</h4>
                                </div>
                                <div className="card-body m-0 p-0">
                                    <div className="row mx-auto">
                                        {questions.map((question, index) =>
                                            <span key={question.id} onClick={() => {
                                                this.setState({
                                                    currentIndex: index
                                                })
                                            }}
                                                  className={`p-3 ml-2 my-2 rounded-sm ${index === currentIndex ? "bg-info" : this.markedForReview(question) ? "bg-warning" : this.questionAlreadyCompleted(question) ? "bg-success" : "bg-secondary"} text-white span-cs-button`}>
                                                {index + 1}
                                            </span>)}
                                    </div>
                                    <hr className="my-3 w-100"/>
                                    <div className="row mx-auto">
                                        <div className="col-12 p-2">
                                            <ColorScheme color="bg-success" text="Completed Questions"/>
                                            <ColorScheme color="bg-secondary" text="Questions Not Yet Attempted"/>
                                            <ColorScheme color="bg-warning" text="Questions Marked For Review"/>
                                            <ColorScheme color="bg-info" text="Current Question"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

function ColorScheme(props) {
    const {text, color} = props;
    return (
        <div className="d-flex align-items-center mb-3">
            <span className={`p-3 rounded ${color} mr-3`}/>
            <p className="m-0">{text}</p>
        </div>
    )
}

function ExamButton(props) {
    const {icon, onClick, text} = props;
    return <button
        className={`btn btn-primary btn-sm mr-3`}
        onClick={onClick}>
        <span className="d-flex">
            <span>{text}</span>
            &nbsp;
            <span className="material-icons">{icon}</span>
        </span>
    </button>
}

export default withAlert()(SingleExamInside);