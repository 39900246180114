import React, {Component} from 'react';
import {makeRequest} from "../../../../../helpers/network_utils";
import {GET_REQUEST} from "../../../../../values/globals";
import endpoints from "../../../../../routes/endpoints";
import {asyncShowAlert} from "../../../../../helpers/helper_functions";
import {getUserObject} from "../../../../../helpers/login";
import LoaderComponent from "../../../../../components/LoaderComponent";
import ChoicesModal from "./choices_modal";

class Question extends Component {

    state = {
        loading: false,
        choices: [],
        currentIndex: null,
        student_answers: []
    }

    componentDidMount() {
        this.getChoices();
    }

    getMyAnswers = () => {
        const user = getUserObject();
        this.setState({
            loading: true
        })
        makeRequest(
            GET_REQUEST,
            `${endpoints.student_answers}?student=${user.id}&answer__question=${this.props.question.id}`,
            {},
            response => {
                const {choices} = this.state;
                if (response.data.length === 0) return;
                const answer = response.data[0].answer;
                for (let i = 0; i < choices.length; i++) {
                    if (choices[i].id === answer.id) {
                        this.setState({
                            currentIndex: i
                        })
                        break;
                    }
                }
                this.setState({
                    student_answers: response.data
                })
            },
            error => {
                asyncShowAlert("error", error.message).finally();
            },
            () => {
                this.setState({loading: false});
            }
        )

    }

    getChoices = () => {
        this.setState({
            loading: true
        })
        makeRequest(
            GET_REQUEST,
            `${endpoints.choices}?question=${this.props.question.id}`,
            {},
            response => {
                this.setState({
                    choices: response.data
                })
            },
            error => {
                asyncShowAlert("error", error.message).finally();
            },
            () => {
                this.setState({loading: false});
                this.getMyAnswers();
            }
        )
    }

    isAnswerCorrect = () => {
        const {choices, currentIndex} = this.state;
        if (currentIndex !== null)
            for (let i = 0; i < choices.length; i++) {
                if (choices[i].is_correct && choices[currentIndex].id === choices[i].id) {
                    return true;
                }
            }
        return false;
    }

    render() {
        const {question, index} = this.props;
        const {choices, currentIndex, loading} = this.state;
        return (
            loading ? <LoaderComponent/> :
                <div className="row mx-auto py-3 pr-1 ">
                    <ChoicesModal choices={choices} id={question.id} currentIndex={currentIndex}/>
                    <div className="col-9 d-flex">
                        <span
                            className={`material-icons mr-3 ${this.isAnswerCorrect() ? "text-success" : "text-danger"}`}>
                            {this.isAnswerCorrect() ? "check" : "close"}
                        </span> {index + 1}. {question.text}
                    </div>
                    <div className="col-3">
                        <button data-toggle="modal" data-target={`#choicesModal${question.id}`}
                                className="btn btn-sm btn-primary">
                            View Answer
                        </button>
                    </div>
                </div>
        );
    }
}

export default Question;