import {combineReducers} from "redux";
import coursesState from './courses/reducers';
import instructorState from './instructors/reducers';
import studentCoursesState from './student_courses/reducers';

export default combineReducers({
    coursesState,
    instructorState,
    studentCoursesState
});

