import {FETCH_STUDENT_COURSES, LOADED_STUDENT_COURSES, LOADING_STUDENT_COURSES} from "./actions";

const studentCoursesState = {
    enrolledCourses: null,
    isLoading: false
};

export default (state = studentCoursesState, action) => {
    if (action.type === FETCH_STUDENT_COURSES) {
        return {...state, enrolledCourses: action.courses, isLoading: false};
    }

    if (action.type === LOADING_STUDENT_COURSES) {
        return {...state, enrolledCourses: action.courses, isLoading: true};
    }

    if (action.type === LOADED_STUDENT_COURSES) {
        return {...state, isLoading: false};
    }
    return state;
};