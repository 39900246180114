import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import LoaderComponent from "../../../components/LoaderComponent";
import IconButton from "../../../components/IconButton";
import Question from "./questions/question";
import AddQuestionModal from "./questions/add_question_modal";
import SubmitExam from "./submit";

class ExamDetails extends Component {
    state = {
        loading: false,
        errors: {},
        questions: [],
        exam: null,
    }

    componentDidMount() {
        this.getExam();
        this.getQuestions();
    }

    getExam = () => {
        const {id} = this.props.match.params;
        this.setState({loading: true});
        makeRequest(
            GET_REQUEST,
            `${endpoints.tests}${id}`,
            {},
            response => {
                this.setState({exam: response.data});
            },
            () => {
                this.setState({error: true});
            }, () => {
                this.setState({
                    loading: false
                })
            }
        );
    }

    getQuestions = () => {
        const {id} = this.props.match.params;
        this.setState({loading: true});
        makeRequest(
            GET_REQUEST,
            `${endpoints.questions}?test=${id}`,
            {},
            response => {
                this.setState({questions: response.data});
            },
            () => {
                this.setState({error: true});
            }, () => {
                this.setState({
                    loading: false
                })
            }
        );
    }

    render() {
        const {loading, exam, questions} = this.state;
        const {id} = this.props.match.params;
        return (
            <div className="container-fluid">
                {loading
                    ? <LoaderComponent/> :
                    <div className="row py-4 mb-4">
                        <AddQuestionModal callback={this.getQuestions} id={id}/>
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h2 className="mb-0">{exam?.name ?? "-"}</h2>
                                    <p className="m-0">Duration: {exam?.duration ?? "-"}</p>
                                </div>

                                <div className="d-flex">
                                    <SubmitExam exam={exam} getExam={this.getExam}/>
                                    <div className="mr-3"/>
                                    <IconButton
                                        icon="edit"
                                        text="Edit"
                                        theme="outline-warning"
                                        onClick={() => this.props.history.push(`/instructors/exams/${id}/edit`)}
                                        size="sm"/>
                                </div>


                            </div>
                            <hr/>
                        </div>
                        <div className="col-12">
                            {
                                questions.map((question, index) => <Question index={index}
                                                                             callback={this.getQuestions}
                                                                             key={question.id}
                                                                             question={question}/>)
                            }
                            {questions.length === 0 && <p className="p-5"> No questions here yet.</p>}
                            <button className="btn btn-primary float-right" data-toggle="modal"
                                    data-target="#addQuestionModal">
                                Add a Question
                            </button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default ExamDetails;