import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {asyncShowAlert, parseErrorResponse} from "../../../helpers/helper_functions";
import LoaderComponent from "../../../components/LoaderComponent";
import ChapterDetails from "./ChapterDetails";
import CourseResources from "../course_resources";
import ResizeSensor from "css-element-queries/src/ResizeSensor";

const $ = window.$;
const parse = require('html-react-parser');

class Learn extends Component {
    state = {
        module: null,
        activeTab: "overview",
        selectedChapter: null,
    }

    componentDidMount() {
        const {id} = this.props.match.params;

        makeRequest(
            GET_REQUEST,
            `${endpoints.courses}${id}/`,
            {},
            response => {
                this.setState({module: response.data}, () => {
                    const learnLeftMenu = $(document).find("#learnLeftMenu");
                    const distance = (learnLeftMenu.offset()?.top - 50);
                    const footer = $("#footer");
                    const contentBody = $('#contentBody');
                    new ResizeSensor(contentBody, function () {
                        if ($(window).width() >= 768) {
                            let top_of_element = footer.offset().top;
                            let bottom_of_element = footer.offset().top + footer.outerHeight();
                            let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                            let top_of_screen = $(window).scrollTop();
                            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                                learnLeftMenu.height($(window).height() - (bottom_of_screen - top_of_element))
                            } else {
                                //Footer isn't visible
                                if (learnLeftMenu.hasClass("fixed-left")) {
                                    learnLeftMenu.height($(window).height());
                                }
                            }
                        }

                    });

                    $(window).scroll(() => {
                        if ($(window).width() >= 768) {
                            let top_of_element = footer.offset().top;
                            let bottom_of_element = footer.offset().top + footer.outerHeight();
                            let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                            let top_of_screen = $(window).scrollTop();
                            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                                //Footer is visible. Lets reduce our div size with every scroll.
                                learnLeftMenu.height($(window).height() - (bottom_of_screen - top_of_element))
                            }

                            if ($(window).scrollTop() >= distance) {
                                if (!learnLeftMenu.hasClass("fixed-left")) {
                                    learnLeftMenu.addClass("fixed-left");
                                }
                            } else {
                                if (learnLeftMenu.hasClass("fixed-left")) {
                                    learnLeftMenu.removeClass("fixed-left");
                                    learnLeftMenu.height($("#root").height());
                                }
                            }
                        }
                    });
                });
            },
            error => {
                if (error.response) {
                    asyncShowAlert("error", parseErrorResponse(error)).finally(() => {
                        this.props.history.goBack();
                    });
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
            }
        )
    }



    handleSelectChapter = (chapter, index, isChapter) => {

        if (chapter === "overview") {
            this.setState({selectedChapter: null, activeTab: "overview"});
        } else {
            const {module} = this.state;

            if (module) {
                this.setState({
                    selectedChapter: {
                        ...chapter,
                        index: isChapter ? index : this.state.selectedChapter.index,
                        isChapter: isChapter,
                        subChapterIndex: isChapter ? this.state.selectedChapter?.index : index,
                    },
                    activeTab: isChapter ? chapter.id : this.state.activeTab,
                    activeSubTab: !isChapter ? chapter.id : null,
                });
            }
        }
    }

    handleClickNext = index => {
        const newIndex = index + 1;
        const nextChapter = this.state.module.course_chapters[newIndex];
        $(`#id${nextChapter.id}`).collapse('show');
        this.handleSelectChapter(nextChapter, newIndex, true);
        window.scrollTo(0, 0)

    }

    handleClickPrev = index => {
        const newIndex = index - 1;
        const nextChapter = this.state.module.course_chapters[newIndex];
        $(`#id${nextChapter.id}`).collapse('show');
        this.handleSelectChapter(nextChapter, newIndex, true);
        window.scrollTo(0, 0)
    }

    render() {
        const {module, activeTab, selectedChapter, activeSubTab} = this.state;
        const {history} = this.props;
        return (
            <div className="container-fluid my-body">
                {module
                    ?
                    <div>
                        <div className="row justify-content-end">
                            <div className="col-xl-8">
                                <div className="pt-4 pb-4 d-flex flex-md-row flex-column justify-content-center">
                                    {
                                        module?.zoom_link ? <button onClick={() => {
                                            window.open(module?.zoom_link);
                                        }} className="button active mr-md-3">
                                            Zoom Meeting
                                        </button> : null
                                    }
                                    <button onClick={() => {
                                        history.push("/students/account")
                                    }} className="button active mr-md-3">
                                        My Modules
                                    </button>
                                    <button onClick={() => {
                                        history.push("/students/account")
                                    }} className="button active mr-md-3">
                                        My Profile
                                    </button>
                                    <button onClick={() => {
                                        history.push(`/learn/modules/${module.id}/discussion`)
                                    }} className="button active mr-md-3">
                                        Discussion
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="row py-4">
                            <div className="col-md-4">
                                <div id="learnLeftMenu"
                                     style={{overflow: "auto", maxWidth: "100%"}}
                                     className="my-card">
                                    <div className="card-header bg-white">
                                        <h5>Module Chapters</h5>
                                    </div>
                                    <ul className="list-group list-group-flush accordion" id={"chaptersList"}>
                                        <h6
                                            onClick={() => this.handleSelectChapter("overview")}
                                            className={`p-3 border-bottom m-0 clickable ${activeTab === "overview" ? "active" : ""}`}>
                                            Course Overview
                                        </h6>
                                        {module?.course_chapters?.map((chapter, index) => (
                                            <div key={chapter.id}>
                                                <div onClick={() => this.handleSelectChapter(chapter, index, true)}
                                                     data-toggle="collapse"
                                                     data-target={`#id${chapter.id}`}
                                                     className={`d-flex align-items-center p-3 clickable 
                                                     flex-wrap ${activeTab === chapter.id ? "active" : ""}`}>
                                                    <h6
                                                        className={`flex-grow-1 p-0`}>
                                                        {chapter.title}
                                                    </h6>
                                                    {/* {
                                                        chapter?.chapter_sub_chapters?.length > 0 ?
                                                            <button
                                                                className="btn btn-light btn-sm p-0">
                                                                <span className="material-icons">
                                                                expand_more
                                                                 </span>
                                                            </button> :
                                                            null
                                                    }*/}

                                                </div>

                                                <div className={`collapse`} data-parent="#chaptersList"
                                                     id={`id${chapter.id}`}>
                                                    {
                                                        chapter?.chapter_sub_chapters?.map((subChapter, index) =>
                                                            <div
                                                                onClick={() => this.handleSelectChapter(subChapter, index)}
                                                                key={subChapter.id}
                                                                className={`d-flex p-2 p-3 
                                                                 align-items-center 
                                                                 clickable
                                                                 ${activeSubTab === subChapter.id ? "active" : "btn-light"}
                                                                 `}>
                                                                <span style={{fontSize: "1rem"}}
                                                                      className="material-icons font-weight-light mr-3">
                                                                    assignment
                                                                </span>
                                                                <p className="small m-0">
                                                                    {subChapter.title}
                                                                </p>
                                                                <hr className="my-0"/>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>

                                        ))}
                                    </ul>
                                    <CourseResources course={module}/>
                                </div>

                            </div>
                            <div id="contentBody" className="col-md-8">
                                <div className="card min-vh-75">
                                    <div className="card-header">
                                        <h5 className="mb-0 text-capitalize">
                                            {activeTab === "overview" ? module.title : selectedChapter?.title}
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        {activeTab === "overview"
                                            ?
                                            <div>
                                                <h5>Overview:</h5>
                                                {parse(module.description || "")}
                                            </div>
                                            :
                                            <div>
                                                <ChapterDetails chapter={selectedChapter}
                                                                key={selectedChapter.id}
                                                                isChapter={selectedChapter.isChapter}/>
                                                <div className="col-md-6 mx-auto pt-4">
                                                    <div className="row">
                                                        <div className="col">
                                                            {selectedChapter.index > 0 && (
                                                                <div className="clickable d-flex"
                                                                     onClick={() => this.handleClickPrev(selectedChapter.index)}>
                                                                    <span
                                                                        className="material-icons">skip_previous</span>
                                                                    Prev
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col">|</div>
                                                        <div className="col">
                                                            {selectedChapter.index < module.course_chapters.length - 1
                                                            && (
                                                                <div className="clickable d-flex"
                                                                     onClick={() => this.handleClickNext(selectedChapter.index)}>
                                                                    Next
                                                                    <span className="material-icons">skip_next</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="p-5">
                        <LoaderComponent/>
                    </div>


                }
            </div>
        );
    }
}

export default Learn;