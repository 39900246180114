import React, {Component} from 'react';
import {Link} from "react-router-dom";
import LoaderComponent from "../../../components/LoaderComponent";
import {getUserObject} from "../../../helpers/login";
import IconButton from "../../../components/IconButton";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import SingleExamSmall from "./single_exam_small";


class ExamsList extends Component {
    state = {
        exams: [],
        isLoading: false
    }

    componentDidMount() {
        const user = getUserObject();
        const query = `?uploaded_by=${user.id}`;
        this.setState({
            isLoading: true
        });
        makeRequest(
            GET_REQUEST,
            `${endpoints.tests}${query}`,
            {},
            response => {
                this.setState({
                    exams: response.data
                })
            },
            null,
            () => {
                this.setState({
                    isLoading: false
                })
            });
    }

    render() {
        const {exams, isLoading} = this.state;

        return (
            <div className="container">
                <div className="row pt-4">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>My Exams</h2>
                            <div>
                                <Link to={`/instructors/exams/add`} className={"mr-3"}>
                                    <IconButton
                                        icon="add"
                                        text="Add Exam"
                                        size="sm"/>
                                </Link>
                            </div>
                        </div>
                        <hr/>
                    </div>
                </div>
                {isLoading ?
                    <LoaderComponent/> :
                    <div className="row">
                        {exams.map(exam => (
                            <SingleExamSmall key={exam.id} exam={exam}/>
                        ))}
                        {exams.length === 0 && (
                            <div className="col-12">
                                <p className="text-center">No exams added yet.</p>
                            </div>
                        )}
                    </div>
                }
            </div>
        );
    }
}

export default ExamsList;