import React, {Component} from 'react';
import Comment from "./comment";
import LoaderComponent from "../../../../../components/LoaderComponent";
import {makeRequest} from "../../../../../helpers/network_utils";
import {GET_REQUEST, POST_REQUEST} from "../../../../../values/globals";
import endpoints from "../../../../../routes/endpoints";

const $ = window.$;
let myIntervalFunc;

class TopicMain extends Component {

    state = {
        isLoadingChat: false,
        isLoading: false,
        topic: {},
        reply_text: "",
        errors: {}
    }

    componentDidMount() {
        this.setState({isLoading: true})
        this.getComments();

        myIntervalFunc = setInterval(this.getComments, 60000);
    }

    componentWillUnmount() {
        clearInterval(myIntervalFunc);
    }

    getComments = () => {
        const id = this.props.id ? this.props.id : this.props?.match?.params?.topic_id;
        makeRequest(GET_REQUEST, `${endpoints.group_discussions}${id}`, {}, response => {
            this.setState({
                topic: response.data
            })
        }, null, () => {
            this.setState({
                isLoading: false
            })
        })
    }

    handleChatSubmit = () => {
        this.setState({isLoadingChat: true})
        const {reply_text} = this.state;
        const id = this.props.id ? this.props.id : this.props?.match?.params?.topic_id;
        makeRequest(POST_REQUEST, `${endpoints.comments}`, {
            reply_text: reply_text,
            discussion: id
        }, () => {
            this.getComments();
            $("#chatInput").collapse('hide');
        }, error => {
            this.setState({
                errors: error.response.data
            })
        }, () => {
            this.setState({
                isLoadingChat: false
            })
        })
    }

    render() {
        const {isLoadingChat, isLoading, topic, errors} = this.state;
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center flex-md-row flex-column">
                    <h5 className='font-weight-bold'>
                        {topic?.title || "-"}
                    </h5>

                    <button data-toggle="collapse" data-target="#chatInput" className="button active">
                        Add Comment
                    </button>
                </div>
                <hr className="m-0"/>
                <div className="collapse" id="chatInput">
                    <div className="form-group m-0">
                        <textarea onChange={e => {
                            this.setState({
                                reply_text: e.target.value
                            })
                        }} className="form-control" rows="3" placeholder="Write Something"/>
                        {errors.reply_text && <p className="small text-danger m-0">{errors.reply_text[0]}</p>}
                    </div>
                    <div className="clearfix">
                        <button onClick={this.handleChatSubmit} disabled={isLoadingChat}
                                className="button active float-right">
                            {isLoadingChat ? "Loading..." : "Submit"}
                        </button>
                    </div>

                </div>
                {isLoading ? <LoaderComponent/> :
                    topic?.comments?.length > 0 ?
                        topic?.comments?.map(comment => <Comment key={comment.id} comment={comment}/>)
                        :
                        <p className="text-center p-3"> No comments have been made yet.</p>
                }
            </div>
        );
    }
}

export default TopicMain;