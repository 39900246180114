import React, {Component} from 'react';
import CustomInput from "../../../../components/custom_input";
import {getUserObject, setUserObject} from "../../../../helpers/login";
import {makeRequest} from "../../../../helpers/network_utils";
import {PATCH_REQUEST} from "../../../../values/globals";
import Swal from "sweetalert2";
import {parseErrorResponse} from "../../../../helpers/helper_functions";
import endpoints from "../../../../routes/endpoints";
import ChangePassword from "./change_password";

class AboutStudent extends Component {
    state = {
        data: {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: ""
        },
        isLoading: false
    }

    componentDidMount() {
        const user = getUserObject();
        const data = this.state.data;
        this.setState({
            data: {
                ...data,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone_number: user.phone_number,
                gender: user.gender
            }
        })
    }

    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            isLoading: true
        })
        makeRequest(PATCH_REQUEST, endpoints.current_user, {...this.state.data}, () => {
            Swal.fire({
                title: 'Success!',
                text: 'Details Edited Successfully!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then()
            setUserObject(null, () => {
                this.setState({});
            });
        }, (error) => {
            Swal.fire({
                title: 'Error!',
                text: parseErrorResponse(error),
                icon: 'error',
                confirmButtonText: 'Ok'
            }).then()
        }, () => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const {isLoading, data} = this.state;
        return (
            <div className="modal fade" id="aboutModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content p-5">
                        <ChangePassword/>
                        <div
                            className="d-flex flex-lg-row flex-column justify-content-between flex-wrap align-items-center my-5">
                            <h4 className="m-0">My Profile</h4>
                            <button data-toggle="modal"
                                    data-target="#resetPasswordModal"
                                    className="button active">
                                Change Password
                            </button>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <CustomInput type={"text"} placeholder={"First Name"} icon={"perm_identity"}
                                         value={data.first_name}
                                         onChange={e => {
                                             const data = this.state.data;
                                             this.setState({
                                                 data: {...data, first_name: e.target.value}

                                             })
                                         }}
                            />
                            <CustomInput type={"text"} placeholder={"Last Name"} icon={"perm_identity"}
                                         value={data.last_name}
                                         onChange={e => {
                                             const data = this.state.data;
                                             this.setState({
                                                 data: {...data, last_name: e.target.value}

                                             })
                                         }}
                            />
                            <CustomInput type={"email"} placeholder={"Email"} icon={"mail_outline"}
                                         value={data.email}
                                         onChange={() => {
                                             const data = this.state.data;
                                             this.setState({
                                                 data: {...data}

                                             })
                                         }}
                            />
                            <CustomInput type={"number"} placeholder={"Phone Number"} icon={"phone"}
                                         value={data.phone_number}
                                         onChange={e => {
                                             const data = this.state.data;
                                             this.setState({
                                                 data: {...data, phone_number: e.target.value}

                                             })
                                         }}
                            />
                            <div className="form-group">
                                <select required onChange={e => {
                                    this.setState({
                                        data: {...data, gender: e.target.value}
                                    })
                                }} className="form-control" placeholder={"Select Organization"}>
                                    <option value={data.gender}>{data.gender || "Select Gender"}</option>
                                    <option key={"male"}
                                            value={"male"}>Male
                                    </option>
                                    <option key={"female"}
                                            value={"female"}>Female
                                    </option>
                                </select>
                            </div>
                            <div className="d-flex flex-lg-row flex-column justify-content-end">
                                <button className="button active">
                                    Save Changes

                                    {isLoading ?
                                        <div className="spinner-border text-white spinner-border-sm ml-2"
                                             role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : null
                                    }
                                </button>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutStudent;