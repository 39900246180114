import React, {Component} from 'react';
import {getUserObject} from "../../../helpers/login";
import placeHolder from "../../../assets/images/lcpb.png";
import {fetchInstructorCourses, fetchInstructorDiscussions} from "../../../redux/instructors/actions";
import {connect} from "react-redux";
import LoaderComponent from "../../../components/LoaderComponent";
import {Link} from "react-router-dom";
import IconButton from "../../../components/IconButton";

const mapStateToProps = state => ({
    discussions: state.instructorState.discussions,
    modules: state.instructorState.courses,
});

const mapDispatchToProps = dispatch => ({
    fetchDiscussions: id => dispatch(fetchInstructorDiscussions(id)),
    fetchCourses: query => dispatch(fetchInstructorCourses(query)),
});

class InstructorProfile extends Component {
    componentDidMount() {
        const user = getUserObject()
        this.props.fetchDiscussions(user.id);

        const query = `?instructor__account=${user.id}`;
        this.props.fetchCourses(query);
    }

    render() {
        const user = getUserObject();
        const {discussions, modules} = this.props;

        return (
            <div className="pt-4">
                <div className="row">
                    <div className="col-md-3 mb-3">
                        <div className="card shadow-sm border-0">
                            <img
                                src={user.photo ? user.photo : placeHolder}
                                alt="profile"
                                className="card-img-top"/>
                            <div className="card-body">
                                <p className="mb-4">
                                    <span className="text-capitalize">
                                        {user.first_name} {user.last_name}
                                    </span>
                                    <br/>
                                    <a href={`mailto:${user.email}`}>{user.email}</a> <br/>
                                    <a href={`tel:+254 ${user.phone_number}`}>+254 {user.phone_number}</a>
                                </p>
                                <Link to={`/instructors/account/edit`}>
                                    <IconButton
                                        text="Update Profile"
                                        icon="edit"
                                        size="sm"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 mb-4">

                        <div className="row mb-4">
                            {modules
                                ?
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                <h3 className="mb-0">My Modules</h3>
                                                <Link to={`/instructors/modules`}>
                                                    <IconButton
                                                        text="View All"
                                                        icon="chat"
                                                        size="sm"/>
                                                </Link>
                                            </div>
                                            <table className="table table-hover">
                                                <tbody>
                                                {modules?.map((module, index) => (
                                                    <tr key={module.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{module.title}</td>
                                                        <td className="text-right">
                                                            <Link
                                                                className="btn btn-primary btn-sm"
                                                                to={`/instructors/modules/${module.id}`}>
                                                                View Module
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <LoaderComponent/>
                            }
                        </div>

                        <div className="row">
                            {discussions
                                ?
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                <h3 className="mb-0">Recent Discussions</h3>
                                                <Link to={`/instructors/discussions`}>
                                                    <IconButton
                                                        text="View All"
                                                        icon="chat"
                                                        size="sm"/>
                                                </Link>
                                            </div>
                                            <table className="table table-hover">
                                                <tbody>
                                                {discussions?.discussions_assigned?.map((discussion, index) => (
                                                    <tr key={discussion.id}>
                                                        <td>{index + 1}</td>
                                                        <th>{discussion.title}</th>
                                                        <td className="text-right">
                                                            <Link
                                                                className="btn btn-primary btn-sm"
                                                                to={`/instructors/discussions/${discussion.id}`}>
                                                                View Discussion
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <LoaderComponent/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstructorProfile);
