import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";

export const FETCH_STUDENT_COURSES = "fetch_courses";

export const LOADING_STUDENT_COURSES = "loading_course";

export const LOADED_STUDENT_COURSES = "loaded_courses";

export const getStudentCourses = (id) => {
    return dispatch => {
        dispatch({
            type: LOADING_STUDENT_COURSES
        });
        makeRequest(
            GET_REQUEST,
            `${endpoints.student_courses}${id}/`,
            {},
            response => {
                dispatch({
                    courses: response.data.courses,
                    type: FETCH_STUDENT_COURSES
                })
            },
            null,
            () => {
                dispatch({type: LOADED_STUDENT_COURSES})
            });
    }
}