import React, {Component} from 'react';
import CustomInput from "../../components/custom_input";
import {getUserObject, setUserObject} from "../../helpers/login";
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST, PATCH_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import Swal from "sweetalert2";
import {asyncShowAlert} from "../../helpers/helper_functions";
import countries from "../../data/countries";
import {Link} from "react-router-dom";

class Register extends Component {

    state = {
        password: "",
        institution: "",
        country: "",
        organizations: [],
        first_name: "",
        last_name: "",
        phone_number: "",
        errors: {}
    }

    componentDidMount() {
        const {history} = this.props;
        const user = getUserObject();
        if (!user.is_first_time_user) {
            history.replace("/");
        }
        this.setState({
            first_name: user.first_name || "",
            last_name: user.last_name || "",
            phone_number: user.phone_number || "",
            country: user.country || "",
            gender: user.gender || ""
        })

        makeRequest(
            GET_REQUEST,
            `${endpoints.organization}`,
            {},
            response => {
                this.setState({organizations: response.data});
            },
            error => {
                if (error.response) {
                    asyncShowAlert("error", error.response.statusText).finally();
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
            }
        )
    }

    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            isLoading: true,
            errors: {}
        })
        const {password, institution, country, first_name, last_name, phone_number, gender} = this.state;

        let data = {
            password: password,
            is_first_time_user: false,
            institution: institution,
            country: country,
            first_name: first_name,
            last_name: last_name,
            phone_number: phone_number,
            gender: gender
        }

        makeRequest(PATCH_REQUEST, endpoints.current_user, data, () => {
            Swal.fire({
                title: 'Success!',
                text: 'Details Edited Successfully!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then()
            setUserObject();
            const user = getUserObject();
            if (user.role === "instructor") {
                window.location.href = "/instructors/account/";
            } else if (user.role === "student") {
                window.location.href = "/students/account/modules";
            } else if (user.role === "admin") {
                window.location.href = "/modules";
            }
        }, (error) => {
            this.setState({
                errors: error.response.data
            })
        }, () => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const {first_name, last_name, phone_number, isLoading, organizations, errors} = this.state;
        return (
            <div className="row mx-auto pt-5 justify-content-center">
                <div className="col-lg-6">
                    <div className="card shadow border-0">
                        <div className="card-header bg-white border-bottom">
                            <h4 className="mt-4 font-weight-bold">
                                Update Your Details and Start Learning!
                            </h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={this.handleSubmit}>
                                {errors.first_name && <p className="small text-danger m-0"> {errors.first_name[0]}</p>}
                                <CustomInput type={"text"} placeholder={"First Name"} icon={"perm_identity"}
                                             value={first_name}
                                             required
                                             onChange={e => {
                                                 this.setState({
                                                     first_name: e.target.value
                                                 })
                                             }}
                                />

                                {errors.last_name && <p className="small text-danger m-0"> {errors.last_name[0]}</p>}
                                <CustomInput type={"text"} placeholder={"Last Name"} icon={"perm_identity"}
                                             value={last_name}
                                             required
                                             onChange={e => {
                                                 this.setState({
                                                     last_name: e.target.value
                                                 })
                                             }}
                                />

                                {errors.phone_number &&
                                <p className="small text-danger m-0"> {errors.phone_number[0]}</p>}
                                <CustomInput type={"number"} placeholder={"Phone Number"} icon={"perm_identity"}
                                             value={phone_number}
                                             required
                                             onChange={e => {
                                                 this.setState({
                                                     phone_number: e.target.value
                                                 })
                                             }}
                                />
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                        </div>
                                        <label>Gender</label>
                                    </div>
                                    <select required onChange={e => {
                                        this.setState({
                                            gender: e.target.value
                                        })
                                    }} className="form-control" placeholder={"Select Organization"}>
                                        <option value="">Select Gender</option>
                                        <option key={"male"}
                                                value={"male"}>Male
                                        </option>
                                        <option key={"female"}
                                                value={"female"}>Female
                                        </option>
                                    </select>
                                    {errors.gender &&
                                    <p className="small text-danger m-0"> {errors.gender[0]}</p>}
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                        </div>
                                        <label>Select Institution</label>
                                    </div>
                                    <select required onChange={e => {
                                        this.setState({
                                            institution: e.target.value
                                        })
                                    }} className="form-control" placeholder={"Select Organization"}>
                                        <option value="">Select Institution</option>
                                        {organizations.map((organization) =>
                                            <option key={organization.id}
                                                    value={organization.id}>{organization.name}</option>
                                        )}
                                    </select>
                                    {errors.institution &&
                                    <p className="small text-danger m-0"> {errors.institution[0]}</p>}
                                </div>

                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                        </div>
                                        <label>Select Country</label>
                                    </div>
                                    <select required onChange={e => {
                                        this.setState({
                                            country: e.target.value
                                        })
                                    }} className="form-control" placeholder={"Select Country"}>
                                        <option value="">Select Country</option>
                                        {countries.map((country) =>
                                            <option key={country.code} value={country.name}>{country.name}</option>
                                        )}
                                    </select>
                                    {errors.country && <p className="small text-danger m-0"> {errors.country[0]}</p>}
                                </div>
                                <div className="my-4">
                                    <input className="mr-2" type="checkbox" name="checkbox" checked readOnly/>
                                    By signing up, you agree to our
                                    <Link to="/terms-of-use" className="text-primary"> Terms of
                                        Use </Link>
                                    and
                                    <Link to="/privacy-policy" className="text-primary"> Privacy
                                        Policy.</Link>
                                </div>
                                <button disabled={isLoading} className="btn btn-block btn-primary">Register Now</button>
                                <p className="my-5 text-center">
                                    Already have an account? <a href="/#"
                                                                className="text-primary">Login</a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;