import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {POST_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {asyncShowAlert} from "../../../helpers/helper_functions";

class InstructorAddCommentReply extends Component {
    state = {
        loading: false,
        errors: {},
    }

    handleSubmitCommentReply = event => {
        event.preventDefault();

        this.setState({loading: true, errors: {}});

        const {id} = this.props.comment;
        const {reply_text} = this.state;

        makeRequest(
            POST_REQUEST,
            endpoints.comment_replies,
            {comment: id, reply_text},
            _ => {
                this.props.refreshDiscussion();
                window.$(`#toggleComment${id}`).click()
            },
            error => {
                if (error.response) {
                    this.setState({errors: error.response.data});
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
            },
            () => {
                this.setState({loading: false});
            }
        )
    }

    render() {
        const {loading, errors} = this.state;
        const {comment, refreshingDiscussion} = this.props;

        return (
            <div>
                {!refreshingDiscussion && (
                    <form
                        className="mt-3"
                        onSubmit={this.handleSubmitCommentReply}>
                        <div className="form-group">
                            <label>Reply:</label>
                            <textarea
                                rows="5"
                                onChange={event => this.setState({reply_text: event.target.value})}
                                className="form-control"
                                required/>
                            {errors.reply_text && (
                                <p className="small text-danger mb-0">{errors.reply_text[0]}</p>
                            )}
                        </div>
                        <div className="text-right">
                            <button
                                onClick={() => window.$(`#toggleComment${comment.id}`).click()}
                                className="btn btn-light btn-sm mr-2"
                                type="button">
                                Cancel
                            </button>
                            <button className="btn btn-primary btn-sm" disabled={loading}>
                                {loading ? "Loading..." : "Submit Reply"}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        );
    }
}

export default InstructorAddCommentReply;