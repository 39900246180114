import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {GET_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {asyncShowAlert} from "../../../../helpers/helper_functions";
import LoaderComponent from "../../../../components/LoaderComponent";
import {Link} from "react-router-dom";
import IconButton from "../../../../components/IconButton";
import InstructorSubChapterAdd from "./SubChapters/Add";
import ReactPlayer from "react-player";

class InstructorChapterDetails extends Component {
    state = {
        chapter: null,
    }

    componentDidMount() {
        const {chapterId} = this.props.match.params;

        makeRequest(
            GET_REQUEST,
            `${endpoints.chapters}${chapterId}/`,
            {},
            response => {
                this.setState({chapter: response.data});
            },
            error => {
                asyncShowAlert("error", error.statusText).finally(() => console.log(error));
            },
        )
    }

    render() {
        const {chapter} = this.state;

        return (
            <div>
                {chapter
                    ?
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="mb-0">{chapter.title}</h3>
                                </div>
                                <div className="card-body">
                                    {chapter.youtube_link && (
                                        <div className="mb-4" style={{height: "30rem"}}>
                                            <ReactPlayer
                                                controls={true}
                                                width={"100%"}
                                                height={"100%"}
                                                url={chapter.youtube_link}/>
                                        </div>
                                    )}

                                    <div dangerouslySetInnerHTML={{__html: chapter.content}}/>

                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <h5>Sub-chapters:</h5>
                                            <ul>
                                                {chapter?.chapter_sub_chapters?.map(subChapter => (
                                                    <li key={subChapter.id}>
                                                        <Link
                                                            to={`/instructors/modules/${chapter.course}/chapters/${chapter.id}/sub/${subChapter.id}`}>
                                                            {subChapter.title}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                            {chapter?.chapter_sub_chapters?.length === 0 && (
                                                <p className="text-muted">Chapter has no subchapters</p>
                                            )}
                                            <IconButton
                                                icon="library_add"
                                                text="Add Sub Chapter"
                                                dataToggle="modal"
                                                size="sm"
                                                dataTarget="#addSubChapterModal"
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div>
                                            <Link
                                                to={`/instructors/modules/chapters/${chapter.id}/edit`}
                                                className="btn btn-primary mx-2">
                                                Edit Chapter
                                            </Link>
                                            <Link
                                                to={`/instructors/modules/chapters/${chapter.id}/delete`}
                                                className="btn btn-danger">
                                                Delete Chapter
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <InstructorSubChapterAdd chapter={chapter}/>
                        </div>
                    </div>
                    :
                    <LoaderComponent/>
                }
            </div>
        );
    }
}

export default InstructorChapterDetails;