import React from "react";
import {useHistory, useLocation} from "react-router";

const colors = [
    "#FFDAB9",
    "#00FF00",
    "#4169E1",
    "#FF6347",
    "#000080",
    "#3CB371",
    "#FFA07A",
    "#20B2AA",
    "#EE82EE",
    "#DC143C",
    "#F4A460"
]


function Comment(props) {

    const comment = props.comment;
    const index = Math.floor((Math.random() * colors.length));
    const history = useHistory();
    const location = useLocation();
    const path = location.pathname;
    return (
        <div>
            <div onClick={()=>{
                history.push(`${path}/${comment.id}`);
            }} className="p-3 border-bottom div-button-white">
                <div className="d-flex flex-wrap align-items-center">
                    <div style={{backgroundColor: colors[index]}} className="rounded-circle p-4 mr-2">

                    </div>
                    <div className="d-flex flex-column flex-wrap">
                        <h6 className="font-weight-bold m-0 mt-1">{comment.user}</h6>
                        <p className="font-weight-light small m-0">
                            {comment.reply_text.length > 20 ? `${comment.reply_text.substring(0, 20)}...` : comment.reply_text}
                        </p>
                        <p style={{color: "var(--primary-color)"}}
                           className="small m-0">{new Date(comment.last_update).toLocaleString()}</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Comment;