import React from "react";
import Facilitator from "./facilitator";

function Facilitators() {
    return (
        <div style={{backgroundColor: "var(--secondary-color)"}}>
            <div className="container pt-5 pb-5">
                <div className="row mx-auto">
                    <Facilitator name={"Dr. Collins Odote"} src={"https://www.youtube.com/embed/6DHqhFCWVgs"}/>
                    <Facilitator name={"Dr. John Githaiga"} src={"https://www.youtube.com/embed/QxUfHWVGHMs"}/>
                    <Facilitator name={"Dr. Robert Kibugi"} src={"https://www.youtube.com/embed/O7qk3Yg_fug"}/>
                    <Facilitator name={"Eng. James Mwangi"} src={"https://www.youtube.com/embed/GLdh4qFGKcc"}/>
                    <Facilitator name={"Prof. Emmanuel Kasimbazi"} src={"https://www.youtube.com/embed/Y52ZvcvYKT0"}/>
                    <Facilitator name={"Prof. Kameri Mbote"} src={"https://www.youtube.com/embed/ZdZuWvFRAT8"}/>
                    <Facilitator name={"Prof. Richard Mulwa"} src={"https://www.youtube.com/embed/RBrt-t5rY0s"}/>
                </div>
            </div>
        </div>
    )
}

export default Facilitators;