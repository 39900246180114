import React from "react";


function GalleryItem(props) {
    const styles = {
        width: "100%",
        height: "50vh",
        objectFit: "cover"
    };
    return (
        <div className="col-md-9 mb-4">
            <div style={{overflow: "hidden"}} className="card">
                <div className="card-img">
                    {props.src ?
                        <iframe allowFullScreen="allowfullscreen" className="m-0" style={styles} src={props.src} title={"Video"}/> :
                        <div style={styles} className="bg-primary"/>
                    }
                </div>
                <div className="card-body">
                    <h5 className="font-weight-bold">
                        {props.name}
                    </h5>
                </div>
            </div>
        </div>
    )
}

export default GalleryItem;