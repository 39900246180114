import React from "react";
import GalleryItem from "./gallery_item";

function Gallery() {
    return (
        <div style={{backgroundColor: "var(--secondary-color)"}}>
            <div className="container pt-5 pb-5">
                <div className="row mx-auto justify-content-center">
                    <GalleryItem name={"Vice Chancellor's opening remarks"} src={"https://www.youtube.com/embed/jSHSAPY5d68"}/>
                </div>
            </div>
        </div>
    )
}

export default Gallery;