export const DOMAIN = "https://83ef-102-219-208-243.ngrok.io/api/v1/";

export const USER_OBJECT = "user_object";

export const JWT_TOKEN = "jwt_token";

//TODO: Remove this
export const REFRESH_TOKEN = "refresh_token";

export const GET_REQUEST = "get_request";
export const POST_REQUEST = "post_request";
export const PUT_REQUEST = "put_request";
export const PATCH_REQUEST = "patch_request";
export const DELETE_REQUEST = "delete_request";

export const QUESTIONS_MARKED_FOR_REVIEW = "questions_marked_for_review";

export const SUBMITTED_TEST = "submitted_test";