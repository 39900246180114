import React from 'react';
import './assets/css/main.scss';
import {BrowserRouter as Router} from "react-router-dom";
import Main from "./main";
import ScrollToTop from "./main/scroll_to_top";
import {Provider} from "react-redux";
import {positions, Provider as TemplateProvider} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import store from "./redux/store";

const options = {
    timeout: 20000,
    position: positions.BOTTOM_RIGHT
};

function App() {

    return (
        <Provider store={store}>
            <TemplateProvider template={AlertTemplate} {...options}>
                <Router>
                    <ScrollToTop>
                        <Main/>
                    </ScrollToTop>
                </Router>
            </TemplateProvider>
        </Provider>
    );
}

export default App;
