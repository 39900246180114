export default {
    courses: "courses/",
    organization: "organizations/",
    verified_courses: "courses/all/",
    enroll_student: "courses/students/enroll/",
    student_courses: "courses/accounts/students/",
    chapters: "courses/chapters/",
    sub_chapters: "courses/chapters/sub-chapters/",
    current_user: "auth/users/me/",
    feedback: "feedback/add/",
    reset_password_confirm: "auth/users/reset_password_confirm/",
    reset_password: "auth/users/reset_password/",
    change_password: "auth/users/set_password/",
    check_enrollment: "courses/accounts/students/check-enrollment/",
    group_discussions: "courses/group-discussions/",
    student_group_discussions: id => `courses/accounts/students/${id}/group-discussions/`,
    comments: "courses/group-discussions/comments/",
    comment_replies: "courses/group-discussions/comments/replies/",
    instructor_group_discussions: id => `/courses/accounts/instructors/${id}/group-discussions/`,
    replies: "courses/group-discussions/comments/replies/",
    course_clicks: "courses/click-counter/",
    tests: "courses/tests/",
    questions: "courses/tests/questions/",
    student_questions: "courses/tests/questions/",
    student_answers: "courses/tests/questions/student-answers/",
    choices: "courses/tests/questions/choices/",
    taken_tests: "courses/students/taken-test-actions/",
    taken_tests_get: "courses/accounts/students/taken-tests/",
    start_test: "courses/tests/start/",
    start_times: "courses/tests/start-times/",
    student_taken_tests: id => `courses/accounts/students/${id}/taken-tests/`
}