import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";

export const FETCH_COURSES = "fetch_courses";

export const LOADING_COURSES = "loading_course";

export const LOADED_COURSES = "loaded_courses";

export const getCourses = () => {
    return dispatch => {
        dispatch({
            type: LOADING_COURSES
        });

        makeRequest(
            GET_REQUEST,
            `${endpoints.verified_courses}`,
            {},
            response => {
                dispatch({
                    courses: response.data,
                    type: FETCH_COURSES
                })
            },
            null,
            () => {
                dispatch({
                    type: LOADED_COURSES
                })
            });
    }
}
