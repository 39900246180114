import React, {Component} from 'react';
import Swal from "sweetalert2";
import {logout} from "../../helpers/login";
import {makeRequest} from "../../helpers/network_utils";
import endpoints from "../../routes/endpoints";
import {POST_REQUEST} from "../../values/globals";

class Reset extends Component {

    state = {
        email: "",
        isLoading: false
    }
    submitForm = event => {
        event.preventDefault();
        this.setState({isLoading: true, errors: {}});
        makeRequest(POST_REQUEST, endpoints.reset_password, {
            email: this.state.email,
        }, () => {
            Swal.fire(
                'Success!',
                'Please check your email for a password reset link',
                'success'
            ).then(() => logout());
            this.setState({
                response: null,
                error: null
            });

        }, (error) => {
            console.log(error);
            Swal.fire(
                'Error!',
                'We could not send a password reset link. Please try again later',
                'error'
            ).then(() => window.location.href = "/login");
            this.setState({
                errors: error.response.data,
                response: null,
                isLoading: false
            });
        }, function () {
        })
    };

    render() {
        const {isLoading} = this.state;
        return (
            <div style={{
                backgroundColor: "var(--secondary-color)"
            }}>
                <div className="row mx-auto justify-content-center p-lg-5 pt-5 pb-5">
                    <div className="col-lg-5">
                        <div className="bg-white rounded shadow">
                            <h3 className="pt-5 pb-4 pl-5 pr-5">
                                Get Your Password
                            </h3>

                            <hr/>
                            <p className="pl-5 pr-5 pt-2 font-weight-light">
                                Lost your password? Please enter your email address.
                                You will receive a link to create a new password via email.
                            </p>
                            <form onSubmit={this.submitForm} className="pl-5 pr-5 pb-5">
                                <div className="form-group">
                                    <input type="email" onChange={e => {
                                        this.setState({
                                            email: e.target.value
                                        })
                                    }} className="form-control" placeholder="Email"
                                           aria-describedby="emailHelp"/>
                                </div>
                                <button disabled={isLoading} style={{
                                    padding: "0.625rem 1.25rem"
                                }} className="button w-100 active">
                                    {isLoading ? "Loading..." : "Reset Password"}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Reset;