import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {Link, Route, Switch} from "react-router-dom";
import LoaderComponent from "../../../components/LoaderComponent";
import InstructorChapterAdd from "./chapters/Add";
import IconButton from "../../../components/IconButton";
import InstructorModuleDelete from "./Delete";
import InstructorChapterDetails from "./chapters/Details";
import InstructorSubChapterDetails from "./chapters/SubChapters/Details";

const ModuleDetails = ({module}) => {
    return (
        <div className="row pt-4">
            <div className="col-12">
                <div className="jumbotron">
                    <h1 className="text-capitalize">{module.title}</h1>
                    <hr className="my-4"/>
                    <div dangerouslySetInnerHTML={{__html: module.description}}/>
                    <div className="row">
                        <div className="col-md-3">
                            <p>
                                <span className="font-weight-bold">Status:</span>&nbsp;
                                {module.is_approved ?
                                    <span className="badge badge-primary badge-pill">Approved</span>
                                    :
                                    <span className="badge badge-danger badge-pill">
                                        Pending Approval
                                    </span>
                                }
                            </p>
                        </div>
                        <div className="col-md-3">
                            <p>
                                <span className="font-weight-bold">Start Time</span>:&nbsp;
                                {new Date(module.start_time).toLocaleString()}
                            </p>
                        </div>
                        <div className="col-md-3">
                            <p>
                                <span className="font-weight-bold">Instructor</span>:&nbsp;
                                {module.instructor?.account?.first_name} {module.instructor?.account?.last_name}
                            </p>
                        </div>
                        <div className="col-md-3">
                            <p>
                                <span className="font-weight-bold">Enrolled Students</span>:&nbsp;
                                {module.students_enrolled?.length}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-4">
                        <div>
                            {module.zoom_link && (
                                <a href={module.zoom_link} target="_blank" rel="noreferrer noopener">
                                    <IconButton
                                        icon="videocam"
                                        text="Zoom Link"/>
                                </a>
                            )}
                        </div>
                        <div>
                            <Link
                                to={`/instructors/modules/${module.id}/edit`}
                                className="btn btn-primary mx-1">
                                Edit Module
                            </Link>
                            <Link
                                to={`/instructors/modules/${module.id}/edit`}
                                data-toggle="modal"
                                data-target="#moduleDeleteModal"
                                className="btn btn-danger mx-1">
                                Delete Module
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

class InstructorCourseDetails extends Component {
    state = {
        module: null,
        error: false,
        refreshing: false,
        activeTab: 0,
        selectedChapter: null,
    }

    componentDidMount() {
        this.fetchCourse();

        const {chapterId} = this.props.match.params;
        this.setState({activeTab: parseInt(chapterId)});
    }

    fetchCourse = () => {
        const {id} = this.props.match.params;

        makeRequest(
            GET_REQUEST,
            `${endpoints.courses}${id}/`,
            {},
            response => {
                this.setState({module: response.data});
            },
            error => {
                this.setState({error: true});
            },
        )
    }

    handleSelectChapter = id => {
        this.setState({activeTab: id})
    }

    handleClickNext = index => {
        const newIndex = index + 1
        const nextChapter = this.state.module.course_chapters[newIndex];
        this.handleSelectChapter(nextChapter.id, newIndex);
    }

    handleClickPrev = index => {
        const newIndex = index - 1
        const nextChapter = this.state.module.course_chapters[newIndex];
        this.handleSelectChapter(nextChapter.id, newIndex);
    }

    refresh = () => {
        this.setState({refreshing: true}, () => {
            this.fetchCourse();
            this.setState({refreshing: false});
        });
    }

    goToChapter = chapterId => {
        const {id} = this.props.match.params;
        this.props.history.push(`/instructors/modules/${id}/chapters/${chapterId}`)
    }

    render() {
        const {module, refreshing, activeTab} = this.state;
        const {subChapterId} = this.props.match.params;

        return (
            <div>
                {module
                    ?
                    <div>
                        <ModuleDetails module={module}/>
                        <InstructorModuleDelete module={module} history={this.props.history}/>

                        <div className="row py-4">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="mb-0">Course Chapters</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <ul className="list-group list-group-flush">
                                            {module?.course_chapters?.map(chapter => (
                                                <li className={`list-group-item`}
                                                    key={chapter.id}
                                                    onClick={() => this.goToChapter(chapter.id)}
                                                >
                                                    <div>
                                                        <h6 className="mb-0 text-capitalize clickable">
                                                            {chapter.title}
                                                        </h6>
                                                        <div
                                                            className={`pl-3 collapse ${activeTab === chapter.id ? "show" : ""}`}>
                                                            <hr/>
                                                            <span>
                                                                <Link
                                                                    className={`${subChapterId ? "text-dark" : ""}`}
                                                                    to={`/instructors/modules/${module.id}/chapters/${chapter.id}`}>
                                                                    Overview
                                                                </Link>
                                                            </span>
                                                            <span>
                                                                {chapter?.chapter_sub_chapters?.map(subChapter => (
                                                                    <Link
                                                                        to={`/instructors/modules/${module.id}/chapters/${chapter.id}/sub/${subChapter.id}`}
                                                                        key={subChapter.id}
                                                                        className={`py-1 text-decoration-none ${subChapterId && parseInt(subChapterId) === subChapter.id ? "text-primary" : "text-dark"}`}>
                                                                        <hr/>
                                                                        {subChapter.title}
                                                                    </Link>
                                                                ))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="py-2 text-center">
                                            <IconButton
                                                icon="library_add"
                                                text="Add Chapter"
                                                dataToggle="modal"
                                                size="sm"
                                                dataTarget="#addModuleChapter"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <Switch>
                                    <Route
                                        path="/instructors/modules/:id/chapters/:chapterId/sub/:subChapterId"
                                        component={props => <InstructorSubChapterDetails {...props}/>}/>
                                    <Route
                                        path="/instructors/modules/:id/chapters/:chapterId"
                                        component={props => <InstructorChapterDetails {...props}/>}/>
                                    <Route
                                        path="/instructors/modules/:id"
                                        component={_ => (
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="mb-3">Click on a chapter to view its content.</p>
                                                    <IconButton
                                                        icon="library_add"
                                                        text="Add Chapter"
                                                        dataToggle="modal"
                                                        size="sm"
                                                        dataTarget="#addModuleChapter"
                                                    />
                                                </div>
                                            </div>
                                        )}/>
                                </Switch>
                                {!refreshing && <InstructorChapterAdd refresh={this.refresh} module={module}/>}
                            </div>
                        </div>
                    </div>
                    :
                    <LoaderComponent/>
                }
            </div>
        );
    }
}

export default InstructorCourseDetails;