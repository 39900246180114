import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {asyncShowAlert} from "../../../../helpers/helper_functions";
import LoaderComponent from "../../../../components/LoaderComponent";
import IconButton from "../../../../components/IconButton";

class InstructorChapterDelete extends Component {
    state = {
        chapter: null,
        loading: false,
    }

    componentDidMount() {
        const {id} = this.props.match.params;

        makeRequest(
            GET_REQUEST,
            `${endpoints.chapters}${id}/`,
            {},
            response => {
                const chapter = response.data;
                this.setState({chapter});
            },
            error => {
                if (error.response.statusText) {
                    asyncShowAlert("error", error.response.statusText).finally(() => console.log(error));
                }
            },
        )
    }

    handleDelete = () => {
        this.setState({loading: true});

        const {id} = this.props.match.params;
        const {chapter} = this.state;

        makeRequest(
            DELETE_REQUEST,
            `${endpoints.chapters}${id}/`,
            {},
            _ => {
                asyncShowAlert("success", "Chapter deleted successfully.").finally();
                this.props.history.push(`/instructors/modules/${chapter.course}`);
            },
            error => {
                this.setState({loading: false});
                if (error.response) {
                    asyncShowAlert("error", "Could not delete chapter.").finally();
                }
            },
        )
    }

    render() {
        const {chapter, loading} = this.state;

        return (
            <div>
                <div className="row py-4">
                    <div className="col-6 mx-auto">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex justify-content-center">
                                    <h4 className="mb-0">Delete Chapter</h4>
                                </div>
                            </div>
                            <div className="text-center card-body">
                                {chapter
                                    ?
                                    <div>
                                        <p className="mb-0">
                                            Chapter: {chapter.title}
                                        </p>
                                        <p>Proceed to delete this chapter?</p>
                                        <IconButton
                                            theme="danger"
                                            icon="delete_forever"
                                            text={loading ? "Loading..." : "Delete Chapter"}
                                            onClick={this.handleDelete}
                                            disabled={loading}/>
                                    </div>
                                    :
                                    <LoaderComponent/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstructorChapterDelete;