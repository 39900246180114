import React, {Component} from 'react';
import SinglePartner from "./single_partner";
import uonLogo from '../../assets/images/uon_logo.gif';
import afdbLogo from '../../assets/images/AFDB_logo.png';
import makLogo from '../../assets/images/mak_logo.png';
import alsfLogo from '../../assets/images/alsf.png';

class Partners extends Component {
    render() {
        return (
            <div className="row mx-auto bg-white p-md-5 justify-content-center">
                <div className="col-12 text-center">
                    <h1 className="p-3 cs-border-bottom-primary">Partners</h1>
                </div>
                <SinglePartner text={"University of Nairobi"}
                               link={"https://www.uonbi.ac.ke/"}
                               image={uonLogo}
                               linkText={"www.uonbi.ac.ke"}/>
                <SinglePartner text={"African Development Bank"}
                               link={"https://www.afdb.org/"}
                               image={afdbLogo}
                               linkText={"www.afdb.org"}/>
                <SinglePartner text={"Makerere University"}
                               link={"https://www.mak.ac.ug/"}
                               image={makLogo}
                               linkText={"www.mak.ac.ug"}/>
                <SinglePartner text={"African Legal Support Facility"}
                               link={"https://www.aflsf.org/"}
                               image={alsfLogo}
                               linkText={"www.aflsf.org"}/>
                {/* <SinglePartner/>
                <SinglePartner/>
                <SinglePartner/>*/}
            </div>
        );
    }
}

export default Partners;