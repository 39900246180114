import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {asyncShowAlert} from "../../../helpers/helper_functions";
import LoaderComponent from "../../../components/LoaderComponent";
import placeHolder from "../../../assets/images/lcpb.png";
import InstructorAddCommentReply from "./AddCommentReply";
import {getUserObject} from "../../../helpers/login";
import InstructorCommentReply from "./Reply";

class InstructorDiscussionDetails extends Component {
    state = {
        discussion: null,
        submittingCommentId: 0,
        refreshingDiscussion: false,
    }

    componentDidMount() {
        this.fetchDiscussion();
    }

    fetchDiscussion = () => {
        const {id} = this.props.match.params;

        makeRequest(
            GET_REQUEST,
            `${endpoints.group_discussions}${id}/`,
            {},
            response => {
                this.setState({discussion: response.data});
            },
            error => {
                if (error.response) {
                    asyncShowAlert("error", error.response.statusText).finally();
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
            }
        )
    }

    refreshDiscussion = () => {
        this.setState(
            {refreshingDiscussion: true},
            () => {
                this.fetchDiscussion();
                this.setState({refreshingDiscussion: false});
            }
        );
    }

    render() {
        const {discussion, refreshingDiscussion} = this.state;
        const user = getUserObject();

        return (
            <div className="container-fluid">
                {discussion
                    ?
                    <div className="row py-4">
                        <div className="col-12">
                            <div className="jumbotron">
                                <h1>{discussion.title}</h1>
                                <hr className="my-4"/>
                                <div className="row">
                                    <div className="col-md-4">
                                        <p className="mb-0">Comments: {discussion.comments?.length}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="mb-0">Members: {discussion.students?.length}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="mb-0">
                                            Created: {new Date(discussion.created_on).toLocaleString()}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <hr className="my-0 mb-3"/>
                            <h4 className="mb-4">Discussion comments:</h4>
                            {discussion.comments?.map(comment => (
                                <div className="row">
                                    <div className="col-2">
                                        <img src={placeHolder} alt="user" className="w-100 shadow-sm"/>
                                    </div>
                                    <div className="col-10">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                {comment.reply_text}
                                            </div>
                                            <div className="card-footer">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="mb-0 small text-muted">
                                                        {comment.user}, {new Date(comment.created_on).toLocaleString()}
                                                    </p>
                                                    <button
                                                        id={`toggleComment${comment.id}`}
                                                        data-target={`#collapseComment${comment.id}`}
                                                        className="btn btn-link btn-sm"
                                                        data-toggle="collapse">
                                                        Reply
                                                    </button>
                                                </div>
                                                <div className="collapse" id={`collapseComment${comment.id}`}>
                                                    <InstructorAddCommentReply
                                                        comment={comment}
                                                        refreshingDiscussion={refreshingDiscussion}
                                                        refreshDiscussion={this.refreshDiscussion}/>
                                                </div>
                                            </div>
                                        </div>
                                        {comment.replies?.map(reply => (
                                            <InstructorCommentReply
                                                reply={reply}
                                                user={user}
                                                refreshDiscussion={this.refreshDiscussion}
                                            />
                                        ))}
                                        <hr className="my-4"/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    :
                    <LoaderComponent/>
                }
            </div>
        );
    }
}

export default InstructorDiscussionDetails;