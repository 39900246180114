import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {GET_REQUEST, PATCH_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {asyncShowAlert} from "../../../../helpers/helper_functions";
import LoaderComponent from "../../../../components/LoaderComponent";
import IconButton from "../../../../components/IconButton";

class InstructorChapterEdit extends Component {
    state = {
        chapter: null,
        editorState: null,
        errors: {},
        loading: false,
    }

    componentDidMount() {
        const {id} = this.props.match.params;

        makeRequest(
            GET_REQUEST,
            `${endpoints.chapters}${id}/`,
            {},
            response => {
                const chapter = response.data;
                this.setState({chapter});
            },
            error => {
                asyncShowAlert("error", error.statusText)
                    .finally(() => console.log(error));
            },
        )

        setTimeout(() => {
                window.$("#moduleDescription").summernote({
                    toolbar: [
                        ['style', ['style']],
                        ['font', ['bold', 'underline', 'clear']],
                        ['color', ['color']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['table', ['table']],
                        ['insert', ['link', 'picture', 'video']],
                        ['view', ['fullscreen', 'codeview', 'help']]
                    ],
                    height: 300,
                    callbacks: {
                        onChange: content => {
                            this.setState({content});
                        }
                    }
                });
            }, 500
        )
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({loading: true});

        const {id} = this.props.match.params;
        const {title, content, youtube_link, photo} = this.state;

        const formData = new FormData();
        if (title) formData.append("title", title);
        if (content) formData.append("content", content);
        if (youtube_link) formData.append("youtube_link", youtube_link);
        if (photo) formData.append("photo", photo);

        makeRequest(
            PATCH_REQUEST,
            `${endpoints.chapters}${id}/`,
            formData,
            response => {
                asyncShowAlert("success", "Chapter updated successfully.").finally();
                this.props.history.push(`/instructors/modules/${response.data.course}/chapters/${response.data.id}`);
            },
            error => {
                if (error.response) {
                    this.setState({errors: error.response.data});
                }
            },
            () => {
                this.setState({loading: false});
            }
        );
    }

    render() {
        const {chapter, errors, loading} = this.state;

        return (
            <div className="container-fluid">
                {chapter
                    ?
                    <div className="row py-4 mb-4">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2 className="mb-0">Edit Chapter</h2>
                                <IconButton
                                    icon="cancel"
                                    text="Cancel"
                                    theme="outline-warning"
                                    onClick={() => this.props.history.goBack()}
                                    size="sm"/>
                            </div>
                            <hr/>
                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="card">
                                <div className="card-body py-4">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label>Chapter Title:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                defaultValue={chapter.title}
                                                onChange={event => this.setState({title: event.target.value})}
                                                required/>
                                            {errors.title && (
                                                <p className="mb-0 small text-danger">{errors.title[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group h-50">
                                            <label>Chapter content:</label>
                                            <textarea id="moduleDescription" required
                                                      defaultValue={chapter.content}/>
                                            {errors.content && (
                                                <p className="mb-0 small text-danger">{errors.content[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>YouTube Link:</label>
                                            <input
                                                type="text"
                                                defaultValue={chapter.youtube_link}
                                                className="form-control"
                                                onChange={event => this.setState({youtube_link: event.target.value})}/>
                                            {errors.youtube_link && (
                                                <p className="mb-0 small text-danger">{errors.youtube_link[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>Photo:</label>
                                            <input
                                                type="file"
                                                className="form-control form-control-file"
                                                onChange={event => this.setState({photo: event.target.files[0]})}
                                            />
                                            {errors.photo && (
                                                <p className="mb-0 small text-danger">{errors.photo[0]}</p>
                                            )}
                                        </div>
                                        <div className="text-center">
                                            <button className="btn btn-primary" disabled={loading}>
                                                {loading ? "Loading..." : "Save Changes"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <LoaderComponent/>
                }
            </div>
        );
    }
}

export default InstructorChapterEdit;