import React from "react";
import {useHistory, useLocation} from "react-router";

function NavButton(props) {
    const location = useLocation();
    const history = useHistory();
    const shouldBeActive = location.pathname.startsWith(props.to);

    return (
        <button data-toggle="collapse" data-target=".navbar-collapse.show" onClick={e => {
            e.preventDefault();
            history.push(props.to)
        }} className={`btn ${shouldBeActive ? "btn-primary" : "btn-outline-primary"}`}>
            {props.children}
        </button>

    )
}

export default NavButton;