import urls from "./urls";
import instructorUrls from "./instructor_urls";
import student_urls from "./student_urls";


export const routes = [
    urls.index,
    urls.home,
    urls.students,
    urls.instructors,
    urls.login,
    urls.register,
    urls.modules_home,
    urls.single_module,
    urls.learn_module,
    urls.module_discussion,
    urls.discussion_topic,
    urls.discussion_comment,
    urls.contact,
    urls.reset,
    urls.reset_password,
    urls.attack,
    urls.logout,
    urls.partners,
    urls.facilitators,
    urls.gallery,
    urls.terms_of_use,
    urls.privacy_policy,
]

export const instructorRoutes = [
    instructorUrls.course_add,
    instructorUrls.course_details,
    instructorUrls.course_edit,
    instructorUrls.course_chapter_details,
    instructorUrls.course_sub_chapter_details,
    instructorUrls.course_sub_chapter_edit,
    instructorUrls.chapter_details,
    instructorUrls.chapter_edit,
    instructorUrls.chapter_delete,
    instructorUrls.group_discussion_details,

    // Account
    instructorUrls.account,
    instructorUrls.account_edit,
    instructorUrls.profile,

    // Modules
    instructorUrls.modules,

    // Discussions
    instructorUrls.group_discussions,

    // Exams
    instructorUrls.exams_list,
    instructorUrls.exams_add,
    instructorUrls.exams_edit,
    instructorUrls.exams_delete,
    instructorUrls.exams_details,
];

export const studentRoutes = [
    student_urls.comment,
    student_urls.topic,
    student_urls.account,
    student_urls.modules,
    student_urls.discussion,
    student_urls.main,
    student_urls.exams_list,
    student_urls.exams_details,
    student_urls.exams_details_start,
    student_urls.exams_details_done
]
