import React, {Component} from "react";
import Reply from "./reply";
import {makeRequest} from "../../../../../../helpers/network_utils";
import {GET_REQUEST, POST_REQUEST} from "../../../../../../values/globals";
import endpoints from "../../../../../../routes/endpoints";

const $ = window.$;
let myIntervalFunc;

class CommentMain extends Component {

    state = {
        comment: {},
        errors: {},
        isLoading: false,
        isLoadingChat: false
    }

    componentDidMount() {
        this.getComments();
        myIntervalFunc = setInterval(this.getComments, 60000);
    }

    componentWillUnmount() {
        clearInterval(myIntervalFunc);
    }

    getComments = () => {
        this.setState({isLoading: true})
        const id = this.props.id ? this.props.id : this.props?.match?.params?.comment_id;
        makeRequest(GET_REQUEST, `${endpoints.comments}${id}`, {}, response => {
            this.setState({
                comment: response.data
            })
        }, null, () => {
            this.setState({
                isLoading: false
            })
        })
    }

    handleChatSubmit = () => {
        this.setState({isLoadingChat: true})
        const {reply_text} = this.state;
        const id = this.props.id ? this.props.id : this.props?.match?.params?.comment_id;
        makeRequest(POST_REQUEST, `${endpoints.replies}`, {
            reply_text: reply_text,
            comment: id
        }, () => {
            this.getComments();
            $("#replyInput").collapse('hide');
        }, error => {
            this.setState({
                errors: error.response.data
            })
        }, () => {
            this.setState({
                isLoadingChat: false
            })
        })
    }

    render() {

        const {history} = this.props;
        const {comment, errors, isLoadingChat, isLoading} = this.state;
        const totalReplies = comment?.replies?.length;
        return (
            isLoading ? <div className="col-12">
                    Loading...
                </div> :
                <div className="col-12">
                    <button onClick={e => {
                        e.preventDefault();
                        history.goBack();
                    }} className="button active mb-3">
                        Go Back
                    </button>
                    <h5 className="font-weight-bold m-0">
                        {comment.user}
                    </h5>
                    <p style={{color: "var(--primary-color)"}} className="mb-3 small">
                        {new Date(comment.last_update).toLocaleString()}
                    </p>
                    <p>
                        {comment.reply_text}
                    </p>
                    <div className="d-flex justify-content-between align-items-center flex-md-row flex-column">
                        <p style={{color: "var(--primary-color)"}} className="mb-3 small">
                            {totalReplies} {totalReplies === 1 ? "Reply" : "Replies"}
                        </p>
                        <button data-toggle="collapse" data-target="#replyInput" className="button active">
                            Add Reply
                        </button>
                    </div>
                    <div className="collapse" id="replyInput">
                        <div className="form-group m-0">
                        <textarea onChange={e => {
                            this.setState({
                                reply_text: e.target.value
                            })
                        }} className="form-control" rows="3" placeholder="Write Something"/>
                            {errors.reply_text && <p className="small text-danger m-0">{errors.reply_text[0]}</p>}
                        </div>
                        <div className="clearfix">
                            <button onClick={this.handleChatSubmit} disabled={isLoadingChat}
                                    className="button active float-right">
                                {isLoadingChat ? "Loading..." : "Submit"}
                            </button>
                        </div>

                    </div>
                    <div className="pl-5">
                        {comment?.replies?.map((reply) => <Reply key={reply.id} reply={reply}/>)}
                    </div>

                </div>
        )
    }
}

export default CommentMain;