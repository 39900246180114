import React, {Component} from 'react';
import TopicMain from "../../../../students/group_discussions/chat/topics/main";

class ModuleTopic extends Component {
    render() {
        const {history, location, match} = this.props;

        return (
            <div className="row mx-auto justify-content-center">
                <div className="col-lg-8 col-md-10 p-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-end align-items-center">
                                <button onClick={e => {
                                    e.preventDefault();
                                    history.goBack();
                                }} className="button active mb-3">
                                    Go Back
                                </button>
                            </div>
                            <TopicMain history={history} location={location} match={match}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModuleTopic;