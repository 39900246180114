import React, {Component} from 'react';
import {makeRequest} from "../../helpers/network_utils";
import {POST_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import Swal from "sweetalert2";

class Contact extends Component {

    state = {
        isLoading: false,
        data: {},
        errors: {}
    }

    handleSubmit = e => {
        e.preventDefault();
        const {data} = this.state;
        this.setState({
            isLoading: true
        })
        makeRequest(POST_REQUEST, endpoints.feedback, data, () => {
            Swal.fire({
                title: 'Success!',
                text: 'Message Sent Successfully!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then()
            this.setState({
                errors: {},
                data: {}
            })
        }, error => {
            this.setState({
                errors: error.response.data
            })
            Swal.fire({
                title: 'Error!',
                text: "Please check your inputs for errors",
                icon: 'error',
                confirmButtonText: 'Ok'
            }).then()
        }, () => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const {isLoading, errors} = this.state;
        return (
            <div className="row p-lg-5 mx-auto justify-content-center">
                <div className="col-12 p-5">
                    <h2 className="cs-border-bottom-primary p-3 text-center font-weight-bold"> Send Message</h2>
                </div>

                <div className="col-lg-10 rounded bg-white">
                    <p className="p-lg-2 text-center font-weight-light lead">
                        Reach out for any information you may need from us.
                    </p>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row mx-auto">
                            <div className="col-lg">
                                <div className="form-group">
                                    <input onChange={e => {
                                        const {data} = this.state;
                                        this.setState({
                                            data: {...data, name: e.target.value}
                                        })
                                    }} type="text" className={`form-control ${errors.name && "border-danger"}`}
                                           placeholder="Name"
                                           aria-describedby="emailHelp"/>
                                    {errors.name && <p className="small text-danger">
                                        {errors.name[0]}
                                    </p>}
                                </div>
                            </div>
                            <div className="col-lg">
                                <div className="form-group">
                                    <input onChange={e => {
                                        const {data} = this.state;
                                        this.setState({
                                            data: {...data, email: e.target.value}
                                        })
                                    }} type="email" className={`form-control ${errors.email && "border-danger"}`}
                                           placeholder="Email"
                                           aria-describedby="emailHelp"/>
                                    {errors.email && <p className="small text-danger">
                                        {errors.email[0]}
                                    </p>}
                                </div>
                            </div>
                            <div className="col-lg">
                                <div className="form-group">
                                    <input onChange={e => {
                                        const {data} = this.state;
                                        this.setState({
                                            data: {...data, phone_number: e.target.value}
                                        })
                                    }} type="number"
                                           className={`form-control ${errors.phone_number && "border-danger"}`}
                                           placeholder="Phone Number"
                                           aria-describedby="emailHelp"/>
                                    {errors.phone_number && <p className="small text-danger">
                                        {errors.phone_number[0]}
                                    </p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <div className="form-group">
                                        <textarea onChange={e => {
                                            const {data} = this.state;
                                            this.setState({
                                                data: {...data, message: e.target.value}
                                            })
                                        }} className={`form-control ${errors.message && "border-danger"}`}
                                                  placeholder="Message" rows="8"/>
                                {errors.message && <p className="small text-danger text-left">
                                    {errors.message[0]}
                                </p>}
                            </div>

                            <button disabled={isLoading} className="button active">
                                Send Message
                                {
                                    isLoading ? <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : null
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Contact;