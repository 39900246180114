import React, {Component} from 'react';
import {getUserObject} from "../../../../helpers/login";
import AboutStudent from "./about";
import StudentModules from "../modules";
import {getStudentCourses} from "../../../../redux/student_courses/actions";
import {connect} from "react-redux";
import GroupDiscussions from "../../group_discussions";
import TopicMain from "../../group_discussions/chat/topics/main";
import CommentMain from "../../group_discussions/chat/topics/comments/main";
import ChangeProfileImage from "./change_profile_image";
import StudentExamsMain from "../exams";

const mapStateToProps = state => ({
    modules: state.studentCoursesState.enrolledCourses,
    isLoading: state.studentCoursesState.isLoading
});

const mapDispatchToProps = dispatch => ({
    fetchCourses: (id) => dispatch(getStudentCourses(id)),
});

class UserProfile extends Component {

    componentDidMount() {
        if (this.props.modules == null)
            getStudentCourses(getUserObject().id);
    }

    render() {
        const user = getUserObject();
        const {pathname} = this.props.location;
        const {history, location} = this.props;
        let path = pathname.substring(pathname.lastIndexOf("/") + 1);
        let topicId = this.props.match.params.id;
        let commentId = this.props.match.params.comment_id;

        if (path === "account") {
            path = "modules";
        } else {
            if (path === commentId) {
                path = "comment"
            } else if (path === topicId) {
                path = "topic"
            }
        }
        const {modules} = this.props;
        const mainPath = "/students/account/";

        return (
            <div className="pt-4 w-100">
                <ChangeProfileImage/>
                <AboutStudent/>
                <div className="row mx-auto justify-content-center">
                    <div className="col-lg-3 col-md-7 mb-3">
                        <div style={{overflow: "hidden"}} className="card shadow-sm border-0">
                            <img
                                style={{
                                    width: "100%",
                                    height: "30vh",
                                    objectFit: "cover"
                                }}
                                src={user.photo ? user.photo : "https://picsum.photos/200"}
                                alt="profile"/>
                            <button data-toggle="modal"
                                    data-target="#changeImageModal" className="button active m-0">
                                Edit Image
                            </button>
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <span className="material-icons text-primary">book</span>
                                    &nbsp;
                                    <h5 className="font-weight-bold mt-2">
                                        {user.first_name} {user.last_name}
                                    </h5>
                                </div>
                                <div>
                                    <p style={{margin: 0, padding: 0}}>
                                        Enrolled Courses : {modules?.length || "-"}
                                    </p>
                                </div>
                                <hr className="my-2"/>
                                <p className="mt-4">
                                    Email Address:
                                </p>
                                <p className="font-weight-bold">
                                    <span className="a-border p-0" onClick={() => {
                                        //history.push(`mailto:${user.email}`)
                                    }}>{user.email}</span>
                                </p>
                                <p className="mt-4">
                                    Phone:
                                </p>
                                <p className="font-weight-bold">
                                    <a href={`tel:${user.profile_number}`} className="a-border p-0">
                                        {user.phone_number || "---"}
                                    </a>
                                </p>
                                <button data-toggle="modal"
                                        data-target="#aboutModal"
                                        className="button active">
                                    Edit Details
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-9 col-md-7 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <ul className="nav nav-tabs mb-4">
                                    <li className="nav-item">
                                        <p className={`nav-link clickable mb-0 ${path === "modules" ? "active" : ""}`}
                                           onClick={() => history.push(`${mainPath}modules`)}>
                                            Modules
                                        </p>
                                    </li>
                                    <li className="nav-item">
                                        <p className={`nav-link clickable mb-0 ${path === "discussions" || path === "topic" || path === "comment" ? "active" : ""}`}
                                           onClick={() => history.push(`${mainPath}discussions`)}>
                                            Discussions
                                        </p>
                                    </li>
                                    <li className="nav-item">
                                        <p className={`nav-link clickable mb-0 ${path === "exams" ? "active" : ""}`}
                                           onClick={() => history.push(`${mainPath}exams`)}>
                                            Completed Exams
                                        </p>
                                    </li>
                                </ul>
                                {path === "modules" && <StudentModules/>}
                                {path === "discussions" && <GroupDiscussions history={history} location={location}/>}
                                {path === "topic" && <TopicMain history={history} location={location} id={topicId}/>}
                                {path === "comment" &&
                                <CommentMain history={history} location={location} id={commentId}/>}
                                {path === "exams" && <StudentExamsMain history={history} location={location}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);