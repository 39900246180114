import React, {Component} from 'react';
import {makeRequest} from "../../../../../helpers/network_utils";
import {GET_REQUEST} from "../../../../../values/globals";
import endpoints from "../../../../../routes/endpoints";
import {asyncShowAlert} from "../../../../../helpers/helper_functions";
import LoaderComponent from "../../../../../components/LoaderComponent";
import {Link} from "react-router-dom";
import InstructorSubChapterDelete from "./Delete";
import ReactPlayer from "react-player";

class InstructorSubChapterDetails extends Component {
    state = {
        chapter: null,
    }

    componentDidMount() {
        const {subChapterId} = this.props.match.params;

        makeRequest(
            GET_REQUEST,
            `${endpoints.sub_chapters}${subChapterId}/`,
            {},
            response => {
                this.setState({chapter: response.data});
            },
            error => {
                asyncShowAlert("error", error.statusText).finally(() => console.log(error));
            },
        )
    }

    render() {
        const {chapter} = this.state;
        const {id, chapterId, subChapterId} = this.props.match.params;

        return (
            <div>
                {chapter
                    ?
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="mb-0">{chapter.title}</h3>
                                </div>
                                <div className="card-body">
                                    {chapter.youtube_link && (
                                        <div className="mb-4" style={{height: "30rem"}}>
                                            <ReactPlayer
                                                controls={true}
                                                width={"100%"}
                                                height={"100%"}
                                                url={chapter.youtube_link}/>
                                        </div>
                                    )}

                                    <div dangerouslySetInnerHTML={{__html: chapter.content}}/>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <div>
                                            <Link
                                                to={`/instructors/modules/${id}/chapters/${chapterId}/sub/${subChapterId}/edit`}
                                                className="btn btn-primary mr-2">
                                                Edit Sub Chapter
                                            </Link>
                                            <button
                                                data-toggle="modal"
                                                data-target="#subChapterDeleteModal"
                                                className="btn btn-danger">
                                                Delete Sub Chapter
                                            </button>
                                        </div>
                                    </div>
                                    <InstructorSubChapterDelete {...this.props} chapter={chapter}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <LoaderComponent/>
                }
            </div>
        );
    }
}

export default InstructorSubChapterDetails;