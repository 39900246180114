import React from "react";

function ChapterResources(props) {
    const resources = props.module?.resources;
    return (
        <div className="bg-white rounded mt-2 mb-2">
            <div className="card-body">
                <h4 className="my-2">
                    Chapter Resources
                </h4>
                <hr className="my-0"/>
            </div>
            <div className="card-body">
                {resources && resources.length > 0 ?
                    resources.map((resource, index) =>
                        <div key={resource.id}>
                            <div className="d-flex flex-column">
                                <h6 className="font-weight-bold mr-md-3 mt-2">
                                    {index + 1}. {resource.title}:
                                </h6>
                                <div className="d-flex flex-column">
                                    {
                                        resource.content && <p className="m-0 p-2">{resource.content}</p>
                                    }
                                    {
                                        resource.document && <span onClick={() => {
                                            window.location.href = resource.document
                                        }} className="a-border m-0 p-2">
                                            Download Document
                                        </span>
                                    }
                                    {
                                        resource.url && <span className="a-border m-0 p-2" onClick={() => {
                                            window.location.href = resource.url
                                        }}>
                                            {resource.url}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    ) :
                    <p>
                        There aren't any resources for this chapter.
                    </p>
                }
            </div>
        </div>
    )
}

export default ChapterResources;