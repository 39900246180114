import React from "react";
import {useHistory} from "react-router";
import {isUserLoggedIn} from "../helpers/login";

function Footer() {
    const history = useHistory();
    return (
        <div id="footer" style={{backgroundColor: "var(--dark-background)"}} className="row p-3 mx-auto text-white">
            <div className="col-lg">
                <span onClick={e => {
                    e.preventDefault();
                    history.push("/")
                }} style={{fontWeight: "bolder", color: "white", fontSize: "2rem", cursor: "pointer"}}
                      className="navbar-brand m-3">LCBP</span>
                <p style={{color: "var(--footer-text-color)"}} className="font-weight-light m-3">
                    Lawyers Capacity Building Program
                </p>
            </div>
            <div className="col-lg">
                <FooterHeader>Contact Us</FooterHeader>
                <FooterLink icon={"email"} text={"lcbp@uonbi.ac.ke"}/>
                <FooterLink icon={"location_on"}
                            to={"https://www.google.com/maps/dir/" +
                            "-1.3182606,36.8357919/University+Of+Nairobi," +
                            "+Arboretum+Drive,+Nairobi+City/@-1.2977868,36.8051322," +
                            "14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x182f17341d3e65d1:0x2d9ab1bc72f9fedb!2m2" +
                            "!1d36.808099!2d-1.27612"}
                            text={"University Of Nairobi, Arboretum Drive, Nairobi City"}/>
            </div>
            <div className="col-lg">
                <FooterHeader>Quick Links</FooterHeader>
                {isUserLoggedIn() ? null : <FooterLink text={"Login"} to="/login"/>}
                <FooterLink text={"Modules"} to="/modules"/>
                <FooterLink text={"Partners"} to="/partners"/>
                <FooterLink text={"Contacts"} to="/contact-us"/>
            </div>
            <div className="col-lg">
                <FooterHeader>Partners</FooterHeader>
                <FooterLink text={"African Development Bank"} to="/partners"/>
                <FooterLink text={"University of Nairobi"} to="/partners"/>
                <FooterLink text={"Makerere University"} to="/partners"/>
            </div>
            {/* <div className="col-lg-4">
                <FooterHeader>News Letter</FooterHeader>

                <p style={{color: "var(--footer-text-color)"}} className="font-weight-light ml-3">
                    Subscribe to get update and information. Don't worry, we won't send spam!
                </p>
                <form>
                    <div className="form-group ml-3">
                        <label style={{color: "var(--footer-text-color)"}} htmlFor="exampleInputEmail1">Email
                            address</label>
                        <input type="email" className="form-control" aria-describedby="emailHelp"/>
                    </div>
                </form>

            </div>*/}
        </div>
    )
}

function FooterHeader(props) {
    return (
        <h5 className="p-3 cs-border-bottom-white">
            {props.children}
        </h5>)
}

function FooterLink(props) {
    const history = useHistory();
    return (
        <div style={{textTransform: "none"}} onClick={e => {
            e.preventDefault();
            if (props.to?.startsWith("http")) {
                window.open(props.to);
            } else {
                history.push(props.to);
            }

        }}
             className="d-flex ml-3 mr-3 mb-1 mt-2 a-border-white">
            {props.icon ? <span className="material-icons mr-2">
                        {props.icon}
                    </span> : null}
            <p className="font-weight-light"> {props.text}</p>
        </div>
    )
}

export default Footer;