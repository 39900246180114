import React, {Component} from 'react';
import {makeRequest} from "../../../../../helpers/network_utils";
import {POST_REQUEST} from "../../../../../values/globals";
import endpoints from "../../../../../routes/endpoints";
import {asyncShowAlert} from "../../../../../helpers/helper_functions";

class InstructorSubChapterAdd extends Component {
    state = {
        addingSubChapter: false,
        errors: {},
    }

    componentDidMount() {
        window.$("#subChapterContent").summernote({
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video']],
                ['view', ['fullscreen', 'codeview', 'help']]
            ],
            height: 300,
            callbacks: {
                onChange: content => {
                    this.setState({content});
                }
            }
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        const {chapter} = this.props;
        const {title, content, youtube_link, photo} = this.state;

        this.setState({addingSubChapter: true, errors: {}});

        const formData = new FormData();
        if (title) formData.append("title", title);
        if (content) formData.append("content", content);
        if (youtube_link) formData.append("youtube_link", youtube_link);
        if (photo) formData.append("photo", photo);
        formData.append("chapter", chapter.id);

        makeRequest(
            POST_REQUEST,
            endpoints.sub_chapters,
            formData,
            _ => {
                asyncShowAlert("success", "Sub chapter added successfully.")
                    .finally(() => window.location.reload());
            },
            error => {
                if (error.response) {
                    this.setState({errors: error.response.data});
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
            },
            () => {
                this.setState({addingSubChapter: false});
            },
        )
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    render() {
        const {addingSubChapter, errors} = this.state;

        return (
            <div className="modal fade" id="addSubChapterModal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="mb-0">Add Sub Chapter</h5>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.handleSubmit}>
                                <label>Title:</label>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={event => this.setState({title: event.target.value})}
                                        required/>
                                    {errors.title && (
                                        <p className="mb-0 small text-danger">{errors.title[0]}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Content:</label>
                                    <textarea id="subChapterContent" required/>
                                    {errors.content && (
                                        <p className="mb-0 small text-danger">{errors.content[0]}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>YouTube Link:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={event => this.setState({youtube_link: event.target.value})}/>
                                    {errors.youtube_link && (
                                        <p className="mb-0 small text-danger">{errors.youtube_link[0]}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Photo:</label>
                                    <input
                                        type="file"
                                        className="form-control form-control-file"
                                        onChange={event => this.setState({photo: event.target.files[0]})}
                                    />
                                    {errors.photo && (
                                        <p className="mb-0 small text-danger">{errors.photo[0]}</p>
                                    )}
                                </div>
                                <div className="text-center">
                                    <button
                                        className="btn btn-primary"
                                        disabled={addingSubChapter}
                                        type="submit">
                                        {addingSubChapter ? "Loading..." : "Submit Sub Chapter"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstructorSubChapterAdd;