import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {POST_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {asyncShowAlert} from "../../../helpers/helper_functions";
import IconButton from "../../../components/IconButton";
import DateTimePicker from "react-datetime-picker";

class InstructorCourseAdd extends Component {
    state = {
        loading: false,
        errors: {},
        startTime: new Date(),
    }

    componentDidMount() {
        window.$("#moduleDescription").summernote({
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video']],
                ['view', ['fullscreen', 'codeview', 'help']]
            ],
            height: 300,
            callbacks: {
                onChange: description => {
                    this.setState({description});
                }
            }
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({loading: true, errors: {}});
        const {title, description, startTime, photo, zoom_link} = this.state;

        const formData = new FormData();
        if (title) formData.append("title", title);
        if (description) formData.append("description", description);
        if (photo) formData.append("photo", photo);
        if (startTime) formData.append("start_time", startTime.toISOString());
        if (zoom_link) formData.append("zoom_link", zoom_link);

        makeRequest(
            POST_REQUEST,
            endpoints.courses,
            formData,
            response => {
                this.props.history.push(`/instructors/modules/${response.data.id}`);
            },
            error => {
                if (error.response) {
                    this.setState({errors: error.response.data});
                } else {
                    asyncShowAlert("error", error.message).finally();
                }
                this.setState({loading: false});
            }
        )
    }

    render() {
        const {loading, errors, startTime} = this.state;

        return (
            <div className="container-fluid">
                <div className="row py-4 mb-4">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="mb-0">Add New Module</h2>
                            <IconButton
                                icon="cancel"
                                text="Cancel"
                                theme="outline-warning"
                                onClick={() => this.props.history.goBack()}
                                size="sm"/>
                        </div>
                        <hr/>
                    </div>
                    <div className="col-md-12 mt-2">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label>Module Title:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={event => this.setState({title: event.target.value})}
                                            required/>
                                        {errors.title && (
                                            <p className="mb-0 small text-danger">{errors.title[0]}</p>
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label>Start Time:</label>
                                            <br/>
                                            <DateTimePicker
                                                value={startTime}
                                                onChange={date => this.setState({startTime: date})}
                                                className="form-control"
                                            />
                                            {errors.start_time && (
                                                <p className="mb-0 small text-danger">{errors.start_time[0]}</p>
                                            )}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Photo:</label>
                                            <input
                                                onChange={event => this.setState({photo: event.target.files[0]})}
                                                type="file"
                                                className="form-control"
                                                required/>
                                            {errors.photo && (
                                                <p className="mb-0 small text-danger">{errors.photo[0]}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Module Description:</label>
                                        <textarea id="moduleDescription" required/>
                                        {errors.description && (
                                            <p className="mb-0 small text-danger">{errors.description[0]}</p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>Zoom Link:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Zoom video call invitation link..."
                                            onChange={event => this.setState({zoom_link: event.target.value})}
                                        />
                                        {errors.zoom_link && (
                                            <p className="mb-0 small text-danger">{errors.zoom_link[0]}</p>
                                        )}
                                    </div>
                                    <div className="text-center">
                                        <button className="btn btn-primary" disabled={loading}>
                                            {loading ? "Loading..." : "Submit Module"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstructorCourseAdd;