import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {POST_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import Swal from "sweetalert2";

const $ = window.$;

class ChangePassword extends Component {
    state = {
        old_password: "",
        new_password: "",
        confirm_password: "",
        isLoading: false,
        errors: {}
    }

    submitForm = event => {
        event.preventDefault();
        this.setState({isLoading: true, errors: {}});
        let errors = {};
        if (this.state.new_password !== this.state.confirm_password) {
            errors.new_password = ["Passwords don't match"];
            this.setState({
                errors: errors,
                isLoading: false
            })
            return;
        }
        makeRequest(POST_REQUEST, endpoints.change_password, {
            current_password: this.state.old_password,
            new_password: this.state.confirm_password
        }, () => {
            Swal.fire(
                'Success!',
                'Your Password was set successfully!',
                'success'
            ).then(() => {
                $("#resetPasswordModal").modal('hide');
            });
            this.setState({
                response: null,
                error: null
            });

        }, (error) => {
            console.log(error);
            Swal.fire(
                'Error!',
                'We could not set your password. Please check your input for errors',
                'error'
            ).then();
            this.setState({
                errors: error.response.data,
                response: null,
                isLoading: false
            });
        }, function () {
        })
    };

    render() {
        const {isLoading, errors} = this.state;
        return (
            <div className="modal fade" id="resetPasswordModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="col-12">
                            <div>
                                <div className="pt-5 pb-4 pl-5 pr-5 d-flex justify-content-between">
                                    <h3 className="m-0">
                                        Change Your Password
                                    </h3>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <hr/>
                                <form onSubmit={this.submitForm} className="pl-5 pr-5 pb-5">
                                    <div className="form-group">
                                        <input type="password" onChange={e => {
                                            this.setState({
                                                old_password: e.target.value
                                            })
                                        }} className="form-control" placeholder="Old Password"
                                               aria-describedby="emailHelp"/>
                                        {
                                            errors.current_password &&
                                            <p className="small text-danger"> {errors.current_password[0]}</p>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <input type="password" onChange={e => {
                                            this.setState({
                                                new_password: e.target.value
                                            })
                                        }} className="form-control" placeholder="New Password"
                                               aria-describedby="emailHelp"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="password"
                                               onChange={event => this.setState({confirm_password: event.target.value})}
                                               className="form-control"
                                               placeholder="Confirm Password"/>
                                        {
                                            errors.new_password &&
                                            <p className="small text-danger"> {errors.new_password[0]}</p>
                                        }
                                    </div>
                                    <button disabled={isLoading} style={{
                                        padding: "0.625rem 1.25rem"
                                    }} className="button w-100 active">
                                        {isLoading ? "Loading..." : "Change Password"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;