import Main from "../pages/students/main";
import Login from "../pages/login/login";
import Module from "../pages/modules/module";
import Contact from "../pages/contact/contact";
import Reset from "../pages/login/reset";
import Test from "../pages/login/test";
import Logout from "../pages/login/logout";
import InstructorWrapper from "../pages/instructors";
import Register from "../pages/register/register";
import Partners from "../pages/partners/partners";
import ModuleDetails from "../pages/modules/Details";
import Learn from "../pages/modules/single_module/Learn";
import Home from "../pages/home/home";
import ResetPassword from "../pages/login/reset_password";
import ModuleDiscussions from "../pages/modules/single_module/index";
import ModuleComment from "../pages/modules/single_module/chat/topics/comments/main";
import ModuleTopic from "../pages/modules/single_module/chat/topics/main";
import Facilitators from "../pages/facilitators";
import Gallery from "../pages/gallery";
import TermsOfUse from "../pages/contact/TermsOfUse";

const urls = {
    index: {
        path: '/',
        exact: true,
        component: Home,
    },

    home: {
        path: '/home',
        exact: true,
        component: Home,
    },

    students: {
        path: '/students',
        exact: false,
        component: Main
    },

    login: {
        path: '/login',
        exact: true,
        component: Login,
    },

    register: {
        path: '/register',
        exact: true,
        component: Register,
    },

    privacy_policy: {
        path: '/privacy-policy',
        exact: true,
        component: Register,
    },

    terms_of_use: {
        path: '/terms-of-use',
        exact: true,
        component: TermsOfUse,
    },

    modules_home: {
        path: '/modules',
        exact: true,
        component: Module,
    },

    single_module: {
        path: '/modules/:id',
        exact: true,
        component: ModuleDetails,
    },

    learn_module: {
        path: '/learn/modules/:id',
        exact: true,
        component: Learn,
    },

    module_discussion: {
        path: '/learn/modules/:id/discussion',
        exact: true,
        component: ModuleDiscussions,
    },

    discussion_topic: {
        path: '/learn/modules/:id/discussion/:topic_id',
        exact: true,
        component: ModuleTopic,
    },

    discussion_comment: {
        path: '/learn/modules/:id/discussion/:topic_id/:comment_id',
        exact: true,
        component: ModuleComment,
    },
    contact: {
        path: '/contact-us',
        exact: true,
        component: Contact,
    },

    reset_password: {
        path: '/auth/password/reset/:uid/:token',
        exact: true,
        component: ResetPassword,
    },

    reset: {
        path: '/reset',
        exact: true,
        component: Reset,
    },

    attack: {
        path: '/test',
        exact: true,
        component: Test,
    },

    logout: {
        path: "/logout",
        exact: true,
        component: Logout
    },

    instructors: {
        path: "/instructors",
        exact: false,
        component: InstructorWrapper,
    },

    partners: {
        path: "/partners",
        exact: false,
        component: Partners,
    },

    facilitators: {
        path: "/facilitators",
        exact: false,
        component: Facilitators,
    },

    gallery: {
        path: "/gallery",
        exact: false,
        component: Gallery,
    }

}
export default urls;