import React, {Component} from "react";
import CommentMain from "../../../../../students/group_discussions/chat/topics/comments/main";

class ModuleComment extends Component {


    render() {
        const {history, location, match} = this.props;
        return (
            <div className="row mx-auto justify-content-center">
                <div className="col-lg-8 col-md-10 p-5">
                    <div className="card">
                        <div className="card-body">
                            <CommentMain history={history} location={location} match={match}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModuleComment;