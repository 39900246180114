import UserProfile from "../pages/students/account/profile";
import SingleExam from "../pages/students/exams/single_exam";
import SingleExamInside from "../pages/students/exams/single_exam_inside";
import AllExams from "../pages/students/exams/all_exams";
import SingleExamComplete from "../pages/students/exams/complete_exams/single_exam_complete";

const student_urls = {
    main: {
        path: 'account',
        exact: false,
        component: UserProfile,
    },
    account: {
        path: 'account/profile',
        exact: false,
        component: UserProfile,
    },

    modules: {
        path: 'account/modules',
        exact: false,
        component: UserProfile,
    },
    discussion: {
        path: 'account/discussions',
        exact: false,
        component: UserProfile,
    },
    topic: {
        path: 'account/discussions/:id',
        exact: false,
        component: UserProfile,
    },
    comment: {
        path: 'account/discussions/:id/:comment_id',
        exact: false,
        component: UserProfile,
    },

    exams_list: {
        path: 'exams',
        exact: true,
        component: AllExams,
    },
    exams_details: {
        path: 'exams/:id',
        exact: true,
        component: SingleExam,
    },
    exams_details_start: {
        path: 'exams/:id/start',
        exact: true,
        component: SingleExamInside,
    },
    exams_details_done: {
        path: 'exams/:id/done',
        exact: true,
        component: SingleExamComplete,
    }
}

export default student_urls;