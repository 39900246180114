import React, {Component} from 'react';
import placeHolder from "../../../assets/images/cool_background.png";
import {Link} from "react-router-dom";
import IconButton from "../../../components/IconButton";

class SingleExamSmall extends Component {
    render() {
        const {exam} = this.props;
        return (
            <div className="col-lg-4 col-md-6 mb-4">
                <div style={{overflow: "hidden"}} className="card h-100 mx-md-3">
                    <img
                        style={{height: "30vh", objectFit: "cover"}}
                        src={exam.photo ? exam.photo : placeHolder}
                        alt="profile"
                        className="card-img-top"/>

                    <div className="card-body text-left">
                        <p style={{fontSize: ".9rem"}}>{exam.name}</p>
                    </div>
                    <div className="card-footer text-center bg-white">
                        <Link to={`/instructors/exams/${exam.id}`}>
                            <IconButton theme="primary" text="View Exam" size="sm"/>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default SingleExamSmall;