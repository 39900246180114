import React, {Component} from 'react';
import {getUserObject} from "../../../../helpers/login";
import {makeRequest} from "../../../../helpers/network_utils";
import {GET_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import Question from "./questions/question";
import LoaderComponent from "../../../../components/LoaderComponent";

class SingleExamComplete extends Component {

    state = {
        loading: false,
        test: null,
        questions: []
    }

    componentDidMount() {
        this.initExam();
    }

    initExam = () => {
        const {id} = this.props.match.params;
        const {history} = this.props;
        this.setState({loading: true});
        const user = getUserObject();

        //Check if user has already taken this test.
        makeRequest(GET_REQUEST, endpoints.student_taken_tests(user.id), {}, response => {
            const tests = response.data.taken_tests;
            for (let i = 0; i < tests.length; i++) {
                let test = tests[i];
                if (test?.test?.id === parseInt(id)) {
                    //User has done this test. Show results
                    this.getTestDetails(test);
                    return;
                }
            }
            history.push(`/students/exams/${id}/start`);
        }, () => {
            this.setState({error: true, loading: true});
        })
    }

    getTestDetails = (test) => {
        makeRequest(
            GET_REQUEST,
            `${endpoints.taken_tests_get}${test?.id}`,
            {},
            response => {
                this.setState({test: response.data}, () => {
                    this.initExamResults();
                });
            },
            () => {
                this.setState({error: true});
            }, () => {
                this.setState({loading: false})
            }
        );
    }

    initExamResults = () => {
        //  const {id} = this.props.match.params;
        const {test} = this.state;
        this.setState({loading: true});
        makeRequest(
            GET_REQUEST,
            `${endpoints.questions}?test=${test?.test?.id}`,
            {},
            response => {
                this.setState({questions: response.data});
            },
            () => {
                this.setState({error: true});
            }, () => {
                this.setState({
                    loading: false
                })
            }
        );
    }

    render() {
        const {questions, loading, test} = this.state;
        return (
            loading ? <LoaderComponent/> : <div className="row justify-content-center py-5 mx-auto">
                <div className="col-lg-8 col-md-10">
                    <div className="card">
                        <div className="card-header bg-primary text-white p-4">
                            <h3 className="font-weight-bold">
                                Your Score: {test?.test_score ?? "-"}%
                            </h3>
                        </div>
                        <div className="card-body bg-warning text-white">
                            <h4 className="font-weight-bold">
                                Total Questions: {questions.length}
                            </h4>
                        </div>
                        <div className="card-footer bg-white">
                            <div className="row mx-auto bg-info text-white p-3">
                                <div className="col-9">
                                    Questions
                                </div>
                                <div className="col-3">
                                    Detail
                                </div>
                            </div>
                            {questions.map((question, index) => <Question key={question.id} question={question}
                                                                          index={index}/>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SingleExamComplete;