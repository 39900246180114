import React, {Component} from 'react';
import Topic from "./chat/topic";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import LoaderComponent from "../../../components/LoaderComponent";
import {getUserObject} from "../../../helpers/login";

class GroupDiscussions extends Component {
    state = {
        isLoading: false,
        groups: []
    }

    componentDidMount() {
        this.setState({isLoading: true})
        const user = getUserObject();
        makeRequest(GET_REQUEST, endpoints.student_group_discussions(user.id), {}, response => {
            this.setState({
                groups: response.data.discussions
            })
        }, null, () => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const {history, location} = this.props;

        const {groups, isLoading} = this.state;
        return (
            <div className="col-12">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className='font-weight-bold'>
                        Discussions
                    </h5>
                </div>
                <hr className="m-0"/>
                {isLoading ? <LoaderComponent/> :
                    groups.length > 0 ?
                        groups.map(group => <Topic key={group.id} history={history} location={location} topic={group}/>)
                        :
                        <p className="text-center p-3"> You have not been added to any discussions.</p>

                }
            </div>
        );
    }
}

export default GroupDiscussions;