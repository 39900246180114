import React, {useEffect} from "react";

const $ = window.$;


function CustomInput(props) {
    useEffect(() => {
        $(".input-main-border").find(":input").focus(function () {
            $(this).parent().css("border-color", "var(--focus-color)");
        }).blur(function () {
            $(this).parent().css("border-color", "#ced4da");
        });
    })
    return (
        <div className="input-group mb-3 input-main-border">
            {props.icon && <div className="input-group-prepend input-main-border-icon">
                 <span
                     className="material-icons input-group-text bg-white font-weight-light">
                    {props.icon}
                </span>
            </div>}
            <input value={props.value} type={props.type}
                   readOnly={props.readOnly}
                   required={props.required}
                   className={`form-control border-left-0 ${props.icon ? "pl-0" : "pl-3"}`}
                   placeholder={props.placeholder} onChange={props.onChange}/>
        </div>
    )
}

export default CustomInput;